import { mapActions, mapGetters, mapMutations } from "vuex";
import { globalLoader } from "@/helpers/variables";
import { VueSlideToggle } from "vue-slide-toggle";
import TrainingBanner from "../traching-banner.vue";

export default {
  name: "training-center",
  components: {
    VueSlideToggle,
    TrainingBanner,
  },
  data() {
    return {
      width: window.innerWidth,
      openList: [],
      loadingSeminars: false,
      filteringSeminars: false,
      filters: null,
      seminarsList: [],
      paginator: {
        hasNextPage: false,
        page: 1,
        perPage: 10,
      },
      dates: null,
      city: "",
      seminar: "",
      theme: "",
    };
  },
  async created() {
    globalLoader(true);

    await Promise.all([await Promise.all([this.fetchPage("seminars"), this.loadSeminars()])]);

    globalLoader(false);
  },
  computed: {
    ...mapGetters({
      page: "pages/page",
    }),
  },
  methods: {
    ...mapMutations({
      showSeminarVideoModal: "popups/SHOW_SEMINAR_VIDEO_MODAL",
    }),
    ...mapActions({
      fetchPage: "pages/GET_PAGE",
      fetchSeminars: "seminars/GET_SEMINARS",
    }),
    updateWidth() {
      this.width = window.innerWidth;
    },
    toggleOpen(id) {
      const index = this.openList.indexOf(id);
      if (index === -1) {
        this.openList.push(id);
      } else {
        this.openList.splice(index, 1);
      }
    },
    isOpen(id) {
      return this.openList.includes(id);
    },
    async loadSeminars() {
      this.loadingSeminars = true;

      const seminarsData = await this.fetchSeminars({
        page: this.paginator.page,
        perPage: this.paginator.perPage,
      });

      if (this.paginator.page === 1) {
        this.seminarsList = [];
      }

      this.seminarsList.push(...seminarsData.data);

      this.paginator.hasNextPage = !!seminarsData.links.next;

      this.loadingSeminars = false;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidth);
  },
};

import Vue from "vue";
import VueTheMask from "vue-the-mask";
import Toasted from "vue-toasted";
import Vuelidate from "vuelidate";
import appLink from "./apilink";

import "moment/locale/uk";
import "moment/locale/ru";

Vue.use(Toasted, {
  duration: 3000,
  position: "top-right",
});
Vue.use(appLink);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(require("vue-moment"));

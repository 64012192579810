import { mapActions, mapGetters } from "vuex";
import { maxLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/uk";

export default {
  name: "user-data",
  data() {
    return {
      payload: {
        secondName: "",
        name: "",
        lastName: "",
        country: "",
        city: "",
        work: "",
        birth_date: "",
      },

      isEdit: false,
      validationErrors: {},
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(191),
      },
      secondName: {
        required,
        maxLength: maxLength(191),
      },
      lastName: {
        required,
        maxLength: maxLength(191),
      },
      country: {
        required,
        maxLength: maxLength(191),
      },
      city: {
        required,
        maxLength: maxLength(191),
      },
      birth_date: {
        required,
      },
    },
  },
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters({
      user: "profile/user",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      updateUserLoading: "profile/updateUserLoading",
    }),
    secondNameErrors() {
      return this.computeErrors("payload", "secondName", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 191 },
      });
    },
    nameErrors() {
      return this.computeErrors("payload", "name", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 191 },
      });
    },
    lastNameErrors() {
      return this.computeErrors("payload", "lastName", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 191 },
      });
    },
    countryErrors() {
      return this.computeErrors("payload", "country", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 191 },
      });
    },
    cityErrors() {
      return this.computeErrors("payload", "city", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 191 },
      });
    },
    addressErrors() {
      return this.computeErrors("payload", "work", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 191 },
      });
    },
    birthDateErrors() {
      return this.computeErrors("payload", "birth_date", {
        required: "validationRequired",
      });
    },
  },
  watch: {
    user(newVal) {
      if (newVal) {
        if (this.user) this.setUserInfo();
      }
    },
  },
  created() {
    if (this.user) this.setUserInfo();
    if (this.isProfessional || this.isDistributor) {
      this.$v.payload.work = {
        required,
        maxLength: maxLength(191),
      };
    }
  },
  methods: {
    ...mapActions({
      updateUserData: "profile/UPDATE_USER_DATA",
      login: "auth/GET_TOKEN",
    }),
    resetForm() {
      this.isEdit = false;
      this.$v.$reset();
      this.setUserInfo();
    },
    setUserInfo() {
      this.payload.secondName = this.user.secondName;
      this.payload.name = this.user.name;
      this.payload.lastName = this.user.lastName;
      this.payload.country = this.user.country;
      this.payload.city = this.user.city;
      const inputDate = new Date(this.user.birthDate);
      this.payload.birth_date = inputDate;
      if (this.user.work) this.payload.work = this.user.work;
    },
    update() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.second_name = this.payload.secondName;
        obj.last_name = this.payload.lastName;
        obj.country = this.payload.country;
        obj.city = this.payload.city;

        let date = new Date(this.payload.birth_date);
        obj.birth_date = new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
          .format(date)
          .replaceAll("/", ".");

        if (this.isProfessional || this.isDistributor) obj.work = this.payload.work;
        this.updateUserData(obj)
          .then(() => {
            this.$toasted.success(this.$t("successResetData"));
            this.isEdit = false;
          })
          .catch(error => {
            const message = error.data?.message;
            if (message) {
              this.$toasted.error(message);
            }
          });
      }
    },
  },
};

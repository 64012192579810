import { mapActions, mapGetters, mapMutations } from "vuex";
import { directive as onClickaway } from "vue-clickaway";
import { getProductPriceTitle, resolveProductImage, routeToProduct } from "@/helpers";
import _ from "lodash";
import typeSelect from "@/components/group-product/components/select/index.vue";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    currentProduct: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    typeSelect,
  },
  name: "selectProduct",
  data() {
    return {
      selectedProduct: "",
      selectedCount: 1,
      openSelect: false,
      isProductPriceInBasket: false,
      selectedProductPrice: "",
      favoriteSelect: false,
      basketKey: 1,
      leftoverType: null,
    };
  },
  computed: {
    ...mapGetters({
      basket: "basket/basket",
    }),
    setCurrentPrice() {
      return parseInt(this.selectedProductPrice.price) * this.selectedCount;
    },
  },
  watch: {
    basket: function () {
      this.basketKey += 1;
      this.checkProductInBasket();
    },
  },
  created() {
    this.selectProductPrice(this.currentProduct.productPrices.data);
    this.isProductPriceInBasket = _.some(this.basket, { select_type: { id: this.selectedProductPrice.id } });
    this.basket.forEach(product => {
      if (product.select_type.id === this.selectedProductPrice.id) {
        return (this.selectedCount = product.select_count);
      }
    });
  },
  methods: {
    ...mapMutations({
      increment: "basket/INCREMENT_BASKET_LIST_COUNT",
      decrement: "basket/DECREMENT_BASKET_LIST_COUNT",
      setCount: "basket/SET_BASKET_LIST_COUNT",
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
      changeBasketPopup: "basket/SHOW_BASKET_POPUP",
    }),
    ...mapActions({
      showNotifyPopup: "products/SHOW_POPUP_PRODUCT_WHEN_AVAILABLE",
    }),

    getOptionTitle(item) {
      this.value = { title: item.title, price: item.price };
      this.selectedProduct = item;
      return item.title;
    },
    routeToProduct: routeToProduct,
    resolveProductImage: resolveProductImage,
    getProductPriceTitle,
    away: function () {
      this.openSelect = false;
    },
    selectProductPrice(productPrice) {
      this.selectedProductPrice = productPrice[0];
    },
    changeCount(type, count = 1) {
      if (type === "increment") {
        this.selectedCount += 1;
      } else if (type === "decrement") {
        this.selectedCount -= 1;
      } else {
        if (count) {
          this.selectedCount = parseInt(count);
        }
      }
    },
    checkProductInBasket() {
      this.basketKey += 2;
      this.isProductPriceInBasket = _.some(this.basket, { select_type: { id: this.selectedProductPrice.id } });
    },
    addBasket() {
      this.addToBasket({
        select_count: this.selectedCount,
        product_price: this.selectedProductPrice,
        product: this.currentProduct,
      });
      this.changeBasketPopup(true);
      this.checkProductInBasket();
    },
    showNotify() {
      const payload = {
        product_id: this.currentProduct.id,
        product_price_id: this.selectedProductPrice.id,
      };

      this.showNotifyPopup(payload);
    },
  },
};

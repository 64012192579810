import { mapGetters, mapMutations } from "vuex";

export default {
  name: "metodistBlock",
  data() {
    return {
      playVideo: false,
    };
  },
  props: {
    info: {},
  },
  computed: {
    ...mapGetters({
      metodistVideo: "metodist/videoLink",
      metodistPopup: "metodist/popupOpen",
    }),
  },
  methods: {
    ...mapMutations({
      openMetodistPopup: "metodist/OPEN_METODIST_POPUP",
    }),
    handleOverlayClick(video) {
      this.openMetodistPopup(video, true);
    },

    handleVideoEnd() {
      this.playVideo = false;
    },
  },
};

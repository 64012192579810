import { VueSlideToggle } from "vue-slide-toggle";
import categorySelect from "../category-select/index.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "category-filter",

  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      parentCategory: "",
      open: false,
      categories: [],
      payload: [],
      urlQuery: "",
      test: [],
      renew: [],
    };
  },
  components: {
    VueSlideToggle,
    categorySelect,
  },
  mounted() {
    this.renewProducts();
  },
  computed: {
    ...mapGetters({
      filters: "products/filters",
    }),
    openRenew() {
      return !!(this.$route.query["brands"]?.includes("renew") && this.openCategory(this.filter));
    },
  },
  methods: {
    setParentCategory(slug) {
      this.parentCategory = slug;
    },
    setParent(item) {
      if (this.parentCategory === "renew" && item === "alphascience") {
        this.setParentCategory("renew");
      } else {
        !this.parentCategory ? this.setParentCategory(item) : this.setParentCategory("");
      }
    },
    openCategory(item) {
      return !!(item.isOpened ^ this.open ^ (item.key === "brands"));
    },
    changeSlideToggle() {
      this.open = !this.open;
    },
    renewProducts() {
      let brands = this.filter.child.data.sort(el => el.key === "brands");

      if (brands) {
        return (this.renew = brands.find(el => el.slug === "renew"));
      }
    },
  },
};

import _ from "lodash";
import basketItem from "./components/basket-item/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import navigateTo from "../../../../mixins/buy-flow";

export default {
  name: "buy-flow",
  mixins: [navigateTo],
  data() {
    return {
      kod: "",
      showBonusesInput: false,
      bonusesAmount: 0,
      disabledButton: false,
      width: window.innerWidth,
    };
  },
  components: {
    basketItem,
  },
  created() {
    this.sync();
    this.checkOrderGift();
    this.getClientPrices();
  },
  watch: {
    bonusesAmount() {
      this.setBonuses({ pay_bonus_amount: this.bonusesAmount });
      this.$refs.bonusesError.innerHTML = "";
      this.disabledButton = false;

      if (this.bonusesAmount > this.user?.bonusAmount) {
        this.$refs.bonusesError.innerHTML = `доступно ${this.user.bonusAmount + " бонусів"}`;
        this.disabledButton = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      basket: "basket/basket",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      isUserWithCode: "profile/isUserWithCode",
      user: "profile/user",
      isAuthenticated: "auth/isAuthenticated",
      giftProduct: "order/giftProduct",
      productsWithDefaultPrices: "basket/productsWithDefaultPrices",
    }),
    checkBasketContainsPromotion() {
      return (
        this.basket.findIndex(
          product => product.select_type.hasActivePromotion || product.select_type.hasIndividualDiscount
        ) !== -1
      );
    },
    totalSum() {
      let bonusesAmount = this.bonusesAmount <= this.user?.bonusAmount ? this.bonusesAmount : 0;

      return _.sumBy(this.basket, product => product.select_type.price * product.select_count) - bonusesAmount;
    },
    getTempDiscount() {
      return this.hasTempDiscount() ? (this.totalSum * 5) / 100 : 0;
    },
    discount() {
      if (!this.canSeeDiscount()) return 0;

      let sum = 0;

      this.basket.forEach(basketProduct => {
        if (!this.productsWithDefaultPrices) return;
        const foundProduct = this.productsWithDefaultPrices.find(product => {
          return product.id === basketProduct.id;
        });

        const clientPrice = foundProduct.productPrices.data.find(defaultPriceProduct => {
          return defaultPriceProduct.value === basketProduct.select_type.value;
        });

        if (!clientPrice) return;
        let discount = 0;
        const price = basketProduct.select_type;
        if (this.isProfessional) {
          if (price.hasIndividualDiscount) {
            discount = price.oldPrice - price.price;
          }
        } else if (this.isDistributor) {
          discount = clientPrice.price - price.price;
        } else {
          sum += (clientPrice.price - price.price) * basketProduct.select_count;
          return;
        }

        sum += discount * basketProduct.select_count;
      });

      return sum;
    },
  },
  methods: {
    ...mapActions({
      getSyncBasket: "basket/GET_SYNC_BASKET",
      fetchDefaultPrices: "basket/GET_DEFAULT_PRICE_BASKET",
      updateUserAssignCode: "profile/UPDATE_USER_DATA",
      fetchUser: "profile/FETCH_USER_DATA",
      checkGift: "order/CHECK_GIFT",
      getClientPriceBasket: "basket/GET_CLIENT_PRICE_BASKET",
    }),
    ...mapMutations({
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
      setBonuses: "basket/SET_USER_BONUSES",
    }),
    async checkOrderGift() {
      try {
        let products = {};
        this.basket.map(item => {
          products[item.select_type.id] = item.select_count;
        });

        const formattedProducts = Object.fromEntries(
          Object.entries(products).map(([key, value]) => [`products[${key}]`, value])
        );

        await Promise.all([this.checkGift(formattedProducts)]);
      } catch (e) {
        console.error(e);
      }
    },
    async getClientPrices() {
      let basketIds = [];
      basketIds = this.basket.map(item => item.id);
      await this.getClientPriceBasket({ ids: basketIds });
    },

    handleOrderClick() {
      this.navigateTo("buy-flow-order");
    },
    hasTempDiscount() {
      return this.user && this.user.tempDiscount;
    },
    canSeeDiscount() {
      return this.user && (this.isUserWithCode || this.isProfessional || this.isDistributor);
    },
    syncProfessional() {
      this.updateUserAssignCode({ professional_id: this.kod })
        .then(() => {
          this.sync();
          this.$toasted.success(this.$t("successChangeCosmetolog"));
        })
        .catch(() => {
          this.$toasted.error(this.$t("errorCodeProfessional"));
        });
    },
    async sync() {
      const ids = _.map(this.basket, "id");
      await this.getSyncBasket({ ids });

      if (!this.isAuthenticated) {
        return;
      }

      await this.fetchUser();
      if (this.isUserWithCode || this.isProfessional || this.isDistributor) {
        await this.fetchDefaultPrices({ ids });
      }
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidth);
  },
};

//utils
import "./utils/plugins";
import "./utils/translations";
import "./utils/global-components";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

//set token after refresh page
if (localStorage.getItem("user_token")) {
    store.commit(`auth/SET_TOKEN`, localStorage.getItem("user_token"));
}

const app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event("render-event"));
    },
});

const eventHub = new Vue();

Vue.mixin({
    data: function() {
        return {
            eventHub: eventHub,
        };
    },
});

// @ts-ignore
Vue.initI18nManager().then(() => {
    app.$mount("#app");
});

import StarRating from "vue-star-rating";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
  name: "comment",
  components: {
    StarRating,
  },
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      user: "profile/user",
      product: "card/product",
      isAuthenticated: "auth/isAuthenticated",
    }),
    messageErrors() {
      return this.computeErrors("payload", "message", {
        required: "validationRequired",
      });
    },
  },
  mixins: [validation],
  validations: {
    payload: {
      message: {
        required,
      },
    },
  },
  data() {
    return {
      showForm: false,
      data: null,
      isOverflowing: false,
      showMore: false,
      payload: {
        message: "",
        productId: "",
        parentId: "",
        name: "",
        email: "",
        rating: 0,
      },
      validationErrors: {},
    };
  },
  watch: {
    showForm() {
      this.payload.parentId = this.comment.id;
      this.payload.productId = this.product.id;
    },
    "comment.message": function (newVal, oldVal) {
      this.checkOverflow();
    },
  },
  created() {
    if (this.user) {
      this.payload.email = this.user.email;
      this.payload.name = this.user.name;
    }
    this.payload.productId = this.product.id;
  },
  mounted() {
    this.checkOverflow();
  },
  methods: {
    ...mapActions({
      sendFeedback: "card/SEND_FEEDBACK",
    }),
    checkOverflow() {
      const container = this.$refs.textContainer;
      this.isOverflowing = container.scrollHeight > container.clientHeight;
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    transformData() {
      return _.mapKeys(this.payload, (value, key) => _.snakeCase(key));
    },
    resetForm() {
      this.payload.message = "";
      this.$v.$reset();
    },
    sendAnswer() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendFeedback(this.transformData()).then(() => {
          this.resetForm();
          this.$toasted.success(this.$t("successSendFeedback"));
        });
      }
    },
  },
};

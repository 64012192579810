import Vue from "vue";
import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { Favorite } from "@/types/shop";

interface State {
	whichList: Array<Favorite>;
}

const state: State = {
	whichList: [],
};

const getters = {
	whichList: (state: State) => state.whichList,
};

const actions = {
	GET_FAVORITES_LIST: async ({ commit }: ActionContext<State, RootState>) => {
		try {
			const response = await $http.get<{ data: Array<Favorite> }>(`/v1/favorites`);

			commit("SET_FAVORITES_LIST", response.data.data);
		} catch (e) {
			throw e;
		}
	},
	ADD_PRODUCT_TO_FAVOURITES: async ({ commit }: ActionContext<State, RootState>, payload: { product_id: number }) => {
		try {
			await $http.post<{ data: Favorite }>(`v1/favorites`, payload);

			commit("ADD_ITEM_TO_FAVOURITES", payload);
		} catch (e) {
			throw e;
		}
	},
	REMOVE_PRODUCT_IN_FAVORITE: async ({ commit }: ActionContext<State, RootState>, id: number | string) => {
		try {
			await $http.delete<{ message: string }>(`v1/favorites/${id}`);

			commit("REMOVE_ITEM_FROM_FAVOURITES", id);
		} catch (e) {
			throw e;
		}
	},
};

const mutations = {
	SET_FAVORITES_LIST(state: State, favorites: Array<Favorite>) {
		state.whichList = favorites;
	},

	ADD_ITEM_TO_FAVOURITES(state: State, payload: any) {
		let found = state.whichList.find(favorite => favorite.productId === payload.productId);
		if (!found) {
			state.whichList.push(payload);
		}
	},

	REMOVE_ITEM_FROM_FAVOURITES(state: State, productId: number) {
		const indexWhichList = state.whichList.findIndex(favorite => favorite.productId === productId);
		Vue.delete(state.whichList, indexWhichList);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

import { mapActions, mapGetters } from "vuex";
import storageInvoice from "@/modules/profile/components/storage-invoice/index.vue";
import { downloadFileFrom } from "@/helpers";

export default {
  name: "distributor-storage-leftovers",
  data() {
    return {
      brandSelected: [],
      seriesSelected: [],
      isOpenBrand: false,
      loadingFile: false,
    };
  },
  components: { storageInvoice },
  async created() {
    await this.fetchRemainings();
  },
  methods: {
    ...mapActions({
      fetchRemainings: "remainings/GET_REMAININGS",
      fetchRemainingsFile: "remainings/GET_REMAINING_FILE",
    }),
    downloadRemainings() {
      this.loadingFile = true;
      this.fetchRemainingsFile()
        .then(response => {
          downloadFileFrom(response.data.data.path, "Залишки по складу.xls");
        })
        .finally(() => {
          this.loadingFile = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      list: "remainings/list",
      loading: "remainings/loading",
    }),
  },
};

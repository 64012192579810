import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:lang?",
      name: "main",
      component: () => import("@/modules/home/index.vue"),
    },
    {
      path: "/:lang?/catalog",
      name: "products",
      component: () => import("@/modules/catalog/index.vue"),
    },
    {
      path: "/:lang?/promotions",
      name: "promotions",
      component: () => import("@/modules/promotions/index.vue"),
    },
    {
      path: "/:lang?/training-center",
      name: "training",
      component: () => import("@/modules/teaching/training-center/index.vue"),
    },
    {
      path: "/:lang?/training-center/:slug",
      name: "seminars",
      component: () => import("@/modules/teaching/seminars/index.vue"),
    },
    {
      path: "/:lang?/forum",
      name: "forum",
      component: () => import("@/modules/teaching/forum/index.vue"),
    },
    {
      path: "/:lang?/card/:slug",
      name: "card",
      component: () => import("@/modules/card/index.vue"),
    },

    {
      path: "/:lang?/server-maintenance",
      name: "server-maintenance",
      component: () => import("@/modules/system/server-maintenance/index.vue"),
    },
    {
      path: "/:lang?/server-error",
      name: "server-error",
      component: () => import("@/modules/system/server-error/index.vue"),
    },
    {
      path: "*",
      name: "page-not-found",
      component: () => import("@/modules/system/page-not-found/index.vue"),
    },
    {
      path: "/:lang?/results",
      name: "result",
      component: () => import("@/modules/search-catalog/index.vue"),
    },
    {
      path: "/:lang?/buy-flow",
      name: "buy-flow",
      redirect: "/:lang?/buy-flow/basket",
      component: () => import("@/modules/buy-flow/index.vue"),
      children: [
        {
          path: "basket",
          name: "buy-flow-basket",
          component: () => import("@/modules/buy-flow/pages/basket/index.vue"),
          meta: {
            title: "Корзина",
          },
        },
        {
          path: "order",
          name: "buy-flow-order",
          component: () => import("@/modules/buy-flow/pages/order/index.vue"),
          meta: {
            title: "Оформлення замовлення",
          },
        },
      ],
    },
    //--------------------------------------pages--------------------------------------
    {
      path: "/:lang?/pages/:slug",
      name: "page",
      component: () => import("@/modules/pages/page-main/index.vue"),
    },
    {
      path: "/:lang?/pages/:slugPage/:slugIngredient",
      name: "sub-page",
      component: () => import("@/modules/pages/sub-page/index.vue"),
    },
    {
      path: "/:lang?/contacts",
      name: "contact-page",
      component: () => import("@/modules/pages/contacts/index.vue"),
    },
    //--------------------------------------pages--------------------------------------

    {
      path: "/:lang?/faq",
      name: "faq",
      component: () => import("@/modules/pages/faq/index.vue"),
    },
    {
      path: "/:lang?/delivery",
      name: "delivery",
      component: () => import("@/modules/pages/delivery-payment/index.vue"),
    },
    {
      path: "/:lang?/profile",
      redirect: "/:lang?/profile/user-info",
      component: () => import("@/modules/profile/index.vue"),
      name: "profile",
      beforeEnter: isAuthenticated,
      children: [
        {
          path: "user-info",
          name: "profile-user-info",
          component: () => import("@/modules/profile/pages/user-info/index.vue"),
        },
        {
          path: "user-professional",
          name: "profile-user-professional",
          component: () => import("@/modules/profile/pages/user-professional/index.vue"),
        },
        {
          path: "user-current-orders",
          name: "profile-user-current-orders",
          component: () => import("@/modules/profile/pages/current-orders/index.vue"),
        },
        {
          path: "user-history-orders",
          name: "profile-user-history-orders",
          component: () => import("@/modules/profile/pages/history-orders/index.vue"),
        },
        {
          path: "managers",
          name: "managers",
          meta: { title: "менеджери" },
          component: () => import("@/modules/profile/pages/managers/index.vue"),
        },
        {
          path: "platform",
          name: "platform",
          component: () => import("@/modules/profile/pages/platform/index.vue"),
          redirect: "/:lang?/profile/platform/seminars",
          children: [
            {
              path: "seminars",
              name: "platform-seminars",
              component: () => import("@/modules/profile/pages/platform/pages/seminars/index.vue"),
            },
            {
              path: "webinar/:id",
              name: "webinar",
              component: () => import("@/modules/profile/pages/platform/pages/webinars/index.vue"),
            },
            {
              path: "webinar/:id/quiz",
              name: "quiz",
              component: () => import("@/modules/profile/pages/platform/pages/test/index.vue"),
            },
            {
              path: "webinar/:id/quiz-result",
              name: "quiz-result",
              component: () => import("@/modules/profile/pages/platform/pages/test-result/index.vue"),
            },
          ],
        },
        {
          path: "user-favorites",
          name: "profile-user-favorites",
          component: () => import("@/modules/profile/pages/favorites/index.vue"),
        },
        {
          path: "user-professional-club",
          name: "user-professional-club",
          redirect: "user-professional-club/clients-list",

          component: () => import("@/modules/profile/pages/professional-club/index.vue"),
          children: [
            {
              path: "clients-list",
              name: "profile-clients-list",
              component: () => import("@/modules/profile/pages/professional-club/pages/clients-list/index.vue"),
            },
            {
              path: "client-history/:id",
              name: "profile-client-history",
              component: () => import("@/modules/profile/pages/professional-club/pages/client-history/index.vue"),
            },
          ],
        },
        {
          path: "distributor-orders",
          name: "distributor-orders",
          redirect: "distributor-orders/moving",

          component: () => import("@/modules/profile/pages/distributor-orders/index.vue"),
          children: [
            {
              path: "moving",
              name: "moving",
              meta: { title: "Переміщення на склад" },
              component: () =>
                import("@/modules/profile/pages/distributor-orders/pages/distributor-orders-moving/index.vue"),
            },
            {
              path: "invoice",
              name: "invoice",
              meta: { title: "Видаткові накладні" },

              component: () =>
                import("@/modules/profile/pages/distributor-orders/pages/distributor-orders-invoice/index.vue"),
            },
          ],
        },
        {
          path: "distributor-storage",
          name: "distributor-storage",
          redirect: "distributor-storage/leftovers",

          component: () => import("@/modules/profile/pages/distributor-storage/index.vue"),
          children: [
            {
              path: "leftovers",
              name: "leftovers",

              component: () =>
                import("@/modules/profile/pages/distributor-storage/pages/distributor-storage-leftovers/index.vue"),
            },
            {
              path: "sales",
              name: "sales",
              meta: { title: "Продажі зі складу" },

              component: () =>
                import("@/modules/profile/pages/distributor-storage/pages/distributor-storage-sales/index.vue"),
            },
            {
              path: "report",
              name: "report",

              component: () =>
                import("@/modules/profile/pages/distributor-storage/pages/distributor-storage-report/index.vue"),
            },
          ],
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    let appElement = document.querySelector(".app");
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition;
      } else {
        appElement.scrollTop = 0;
        return { x: 0, y: 0 };
      }
    }
  },
});

export default router;

import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import navigateTo from "../../../mixins/buy-flow";
import typeSelect from "./components/select/index.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { getProductPriceTitle, resolveProductImage, routeToProduct } from "@/helpers";
import togetherProduct from "./components/together-product/index.vue";

export default {
  name: "basket",
  mixins: [navigateTo],
  data() {
    return {
      carouselKey: 1,
      selectedProductPrice: {},
      productsKey: 1,
      productsSlider: {
        infinite: true,
        centerPadding: "0",
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        centerMode: true,
        responsive: [
          {
            breakpoint: 672,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },

  watch: {
    $route: function () {
      document.getElementById("html").classList.remove("hide");
      this.changeBasketPopup(false);
    },
  },
  components: {
    typeSelect,
    VueSlickCarousel,
    togetherProduct,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      user: "profile/user",
      buyTogetherProducts: "basket/buyTogetherProducts",
      basket: "basket/basket",
      giftProduct: "order/giftProduct",
    }),
    sortedBuyProducts() {
      return this.buyTogetherProducts.map(product => ({
        ...product,
        productPrices: {
          data: product.productPrices.data.sort((a, b) => (a.value > b.value ? -1 : 1)),
        },
      }));
    },
    hideSliderArrows() {
      switch (true) {
        case window.innerWidth > 672 && this.buyTogetherProducts.length >= 4:
          return true;
        case window.innerWidth < 672 && window.innerWidth > 500 && this.buyTogetherProducts.length >= 3:
          return true;
        case window.innerWidth <= 500 && this.buyTogetherProducts.length >= 2:
          return true;
        default:
          return false;
      }
    },
    totalSum() {
      return _.sumBy(this.basket, product => product.select_type.price * product.select_count);
    },
    getTempDiscount() {
      return this.hasTempDiscount() ? (this.totalSum * 5) / 100 : 0;
    },
  },
  created() {
    document.getElementById("html").classList.add("hide");
    this.getBuyTogetherProducts();
    this.checkOrderGift();
  },
  methods: {
    routeToProduct,
    getProductPriceTitle,
    resolveProductImage,
    ...mapMutations({
      increment: "basket/INCREMENT_BASKET_LIST_COUNT",
      decrement: "basket/DECREMENT_BASKET_LIST_COUNT",
      setCount: "basket/SET_BASKET_LIST_COUNT",
      remove: "basket/REMOVE_ITEM_FROM_BASKET",
      changeBasketPopup: "basket/SHOW_BASKET_POPUP",
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
    }),
    ...mapActions({
      fetchBuyTogetherProducts: "basket/GET_BUY_TOGETHER_PRODUCT",
      checkGift: "order/CHECK_GIFT",
    }),
    /** @param {Product} product */
    addBasket(product) {
      let sorted = product.productPrices.data.sort(this.compare);
      let selectedProductPrice = sorted[1] ? sorted[1] : sorted[0];
      this.productsKey += 1;

      if (product.hasDuplicate) {
        this.$toasted.info("Цей товар переміщено до нової серії");
        return;
      }

      this.addToBasket({
        select_count: 1,
        product_price: selectedProductPrice,
        product: product,
        unauthorizedProductPriceId: this.product.unauthorizedProductPriceId,
      });
      fbq("track", "AddToCart", {
        content_type: "product",
        currency: "UAH",
        value: selectedProductPrice.price,
        content_name: product.title,
        content_ids: product.unauthorizedProductPriceId,
        content_category: product.brandSeries?.title || product.brand?.title || product.title,
      });
    },
    async checkOrderGift() {
      try {
        let products = {};
        this.basket.map(item => {
          products[item.select_type.id] = item.select_count;
        });

        const formattedProducts = Object.fromEntries(
          Object.entries(products).map(([key, value]) => [`products[${key}]`, value])
        );

        await this.checkGift(formattedProducts);
      } catch (e) {
        console.error(e);
      }
    },

    getLargeValue(products) {
      return products[0];
    },
    closePopup() {
      document.getElementById("html").classList.remove("hide");
      this.changeBasketPopup(false);
    },
    toProduct(product) {
      document.getElementById("html").classList.remove("hide");
      this.changeBasketPopup(false);
    },
    goToOrder() {
      document.getElementById("html").classList.remove("hide");
      this.changeBasketPopup(false);
      this.navigateTo("buy-flow-order");
    },
    hasTempDiscount() {
      return this.user && this.user.tempDiscount;
    },
    async removeProduct(item) {
      this.remove(item);
      await this.checkOrderGift();
    },
    /**
     *
     * @param {string} type
     * @param count
     * @param {BasketProduct} basketProduct
     */
    async changeCount(type, count = 1, basketProduct) {
      if (type === "increment") {
        this.increment(basketProduct);
      } else if (type === "decrement") {
        this.decrement(basketProduct);
      } else {
        if (count) {
          this.setCount({
            product: basketProduct,
            select_count: parseInt(count),
          });
        }
      }
      await this.checkOrderGift();
    },
    async getBuyTogetherProducts() {
      await this.fetchBuyTogetherProducts({ ids: _.map(this.basket, "id") });
      this.carouselKey += 1;
    },
    showNext() {
      this.$refs.productsSlider.next();
    },
    showPrev() {
      this.$refs.productsSlider.prev();
    },
  },
};

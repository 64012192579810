import { mapActions, mapGetters, mapMutations } from "vuex";
import { every, map, some, sumBy } from "lodash";
import deliveryNovaPoshta from "../../components/delivery-nova-poshta/index.vue";
import deliveryNovaPoshtaCourier from "../../components/delivery-nova-poshta-courier/index.vue";
import deliveryNovaPoshtaCompany from "@/modules/buy-flow/components/delivery-nova-poshta-company/index.vue";
import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import { getProductPriceTitle, makeid, resolveProductImage, routeToProduct } from "@/helpers";
import DistributorOrders from "../../../profile/pages/distributor-orders";
import validation from "@/mixins/validation";

const DeliveryType = {
  PICKUP: "pickup",
  STORAGE: "storage",
  ADDRESS: "address",
  COURIER: "courier",
};

const PaymentProcessType = {
  CARD_ONLINE: "card_online",
  PAY_WITH_DETAILS: "pay_with_details",
  ELECTRONIC: "electronic",
};

const RecipientType = {
  ME: 0,
  ANOTHER: 1,
};

export const OrderType = {
  TRANSFER: "transfer",
  CLIENTSALE: "clientsale",
  SALE: "sale",
};

const organizationValidations = {
  organizationData: {
    edrpouCode: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(8),
    },
    companyName: { required },
    ownershipType: { required },
  },
};

export default {
  name: "order",
  mixins: [validation],
  components: {
    DistributorOrders,
    deliveryNovaPoshta,
    deliveryNovaPoshtaCourier,
    deliveryNovaPoshtaCompany,
  },
  data() {
    return {
      DeliveryType,
      RecipientType,
      OrderType,
      orderTypes: [
        {
          id: OrderType.TRANSFER,
          title: this.$t("Склад"),
        },
        {
          id: OrderType.SALE,
          title: this.$t("Видаткова накладна"),
        },
      ],
      loadingAddresses: false,
      showBonusesInput: false,
      bonusesAmount: "Введіть кількість бонусів",
      liqpay: "",
      isAuth: localStorage.getItem("user_token"),
      recipientPhoneError: false,
      recipientNameError: false,
      recipientPatronymicError: false,
      recipientSecondNameError: false,
      disabled: false,
      selectedAddressFromList: false,
      goes_to_patient: false,
      selectedDelivery: {
        id: 1,
        type: DeliveryType.PICKUP,
      },
      hidePromocod: true,
      selectedPayment: {},
      do_not_callback: false,
      selectedRecipient: {
        id: RecipientType.ME,
      },
      recipientList: [
        {
          id: RecipientType.ME,
          title: "Я",
        },
        {
          id: RecipientType.ANOTHER,
          title: this.$t("anotherPerson"),
        },
      ],
      deliveryTown: true,
      deliveryDepartment: true,
      certificateFlag: false,
      selectedCertificate: "",
      payload: {
        recipientName: "",
        recipientPhone: "",
        recipientSecondName: "",
      },
      info: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        additionalInformation: "",
        patronymic: "",
      },
      loadingPromoCode: false,
      organization: false,
      organizationData: {
        edrpouCode: null,
        companyName: null,
        ownershipType: null,
      },
      selectedDocument: OrderType.SALE,
      randomHash: "",
      promocode: "",
      promocodeProducts: null,
      promocodeResponse: null,
      promocodeActivated: false,
      validationErrors: {},
    };
  },
  validations() {
    if (this.organization) {
      if (this.selectedDelivery.type === DeliveryType.PICKUP) {
        return {
          ...organizationValidations,
          info: {
            firstName: {
              required,
            },
            lastName: {
              required,
            },
            phone: {
              required,
            },
            email: {
              email,
              required,
            },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.ADDRESS && this.isAuthenticated) {
        return {
          ...organizationValidations,
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { email, required },
            patronymic: {
              required,
            },
          },
          payload: {
            id: { required },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.ADDRESS && !this.isAuthenticated) {
        return {
          ...organizationValidations,
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { required, email },
            patronymic: {
              required,
            },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.STORAGE) {
        return {
          ...organizationValidations,
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { required, email },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.ADDRESS || this.selectedDelivery.type === DeliveryType.COURIER) {
        return {
          ...organizationValidations,
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { required, email },
          },
        };
      }
    } else {
      if (this.selectedDelivery.type === DeliveryType.PICKUP) {
        return {
          info: {
            firstName: {
              required,
            },
            lastName: {
              required,
            },
            phone: {
              required,
            },
            email: {
              email,
              required,
            },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.ADDRESS && this.isAuthenticated) {
        return {
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { email, required },
            patronymic: {
              required,
            },
          },
          payload: {
            id: { required },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.ADDRESS && !this.isAuthenticated) {
        return {
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { required, email },
            patronymic: {
              required,
            },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.STORAGE) {
        return {
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { required, email },
          },
        };
      }
      if (this.selectedDelivery.type === DeliveryType.ADDRESS || this.selectedDelivery.type === DeliveryType.COURIER) {
        return {
          info: {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { required, email },
          },
        };
      }
    }
  },
  computed: {
    ...mapGetters({
      dataFromAddressesList: "order/savedAddress",
      deliveryMethods: "order/deliveryMethods",
      courierProduct: "order/courierProduct",
      basket: "basket/basket",
      basketResponse: "basket/basketResponse",
      user: "profile/user",
      isAuthenticated: `auth/isAuthenticated`,
      addressNovaPoshta: `order/address`,
      deliveryAddresses: "order/addresses",
      addressNovaPoshtaCourier: `order/addressCourier`,
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      isUserWithCode: "profile/isUserWithCode",
      canUseBonuses: "profile/canUseBonuses",
      selectedBonuses: "basket/selectedBonuses",
      //------------------------------------------------------------------------
      certificate: "order/certificate",
      //-----------------------------------payment------------------------------
      invoiceResult: "order/invoiceResult",
      isInvoiceLoading: "order/isInvoiceLoading",
      transactionResult: "order/transactionResult",
      isTransactionLoading: "order/isTransactionLoading",
      orderResult: "order/orderResult",
      savedAddress: "order/savedAddress",
      loadingOrder: "order/isLoading",
      //---------------------------------delivery-------------------------------
      deliveryAddress: "order/deliveryAddress",
      //---------------------------------remaining------------------------------
      orderCheckResult: "order/orderCheckResult",
      //---------------------------------other----------------------------------
      addressesListPopup: "popups/addressesListPopup",
      //---------------------------------gift-----------------------------------
      giftProduct: "order/giftProduct",
      //---------------------------------products with default price------------
      productsWithDefaultPrices: "basket/productsWithDefaultPrices",
    }),

    isOnlyWebinars() {
      return every(this.basket, { type: "webinar" });
    },
    isAwebinar() {
      return some(this.basket, product => product.type === "webinar");
    },
    isOnlyProducts() {
      let isWebinar = some(this.basket, product => product.type === "webinar");
      let isTicket = some(this.basket, product => product.type === "ticket");
      let isProduct = some(this.basket, product => product.type === "regular");
      return !((isWebinar && !isProduct) || (isTicket && !isProduct));
    },
    patronymicErrors() {
      return this.computeErrors("info", "patronymic", {
        required: "validationRequired",
      });
    },
    orgEdrpouCodeErrors() {
      return this.computeErrors("organizationData", "edrpouCode", {
        required: "validationRequired",
        minLength: { message: "validationMin", count: 8 },
        maxLength: { message: "validationMax", count: 8 },
      });
    },
    orgCompanyNameErrors() {
      return this.computeErrors("organizationData", "companyName", {
        required: "validationRequired",
      });
    },
    orgOwnershipTypeErrors() {
      return this.computeErrors("organizationData", "ownershipType", {
        required: "validationRequired",
      });
    },
    firstNameErrors() {
      return this.computeErrors("info", "firstName", {
        required: "validationRequired",
      });
    },
    phoneErrors() {
      return this.computeErrors("info", "phone", {
        required: "validationRequired",
      });
    },
    lastNameErrors() {
      return this.computeErrors("info", "lastName", {
        required: "validationRequired",
      });
    },
    emailErrors() {
      return this.computeErrors("info", "email", {
        required: "validationRequired",
        email: "validationEmail",
      });
    },
    totalSum() {
      const bonusesAmount = this.bonusesAmount <= this.user?.bonusAmount ? this.selectedBonuses.pay_bonus_amount : 0;
      if (this.promocodeResponse && !!this.basket[0].select_type.oldPrice)
        return sumBy(this.basket, product => product.select_type.oldPrice * product.select_count) - bonusesAmount;
      return sumBy(this.basket, product => product.select_type.price * product.select_count) - bonusesAmount;
    },
    getTempDiscount() {
      return this.hasTempDiscount() ? (this.totalSum * 5) / 100 : 0;
    },
    discount() {
      if (!this.canSeeDiscount()) return 0;

      let sum = 0;

      this.basket.forEach(basketProduct => {
        if (!this.productsWithDefaultPrices) return;
        const foundProduct = this.productsWithDefaultPrices.find(product => {
          return product.id === basketProduct.id;
        });
        const clientPrice = foundProduct.productPrices.data.find(defaultPriceProduct => {
          return defaultPriceProduct.value === basketProduct.select_type.value;
        });

        if (!clientPrice) return;
        let discount = 0;

        const price = basketProduct.select_type;
        if (this.isProfessional) {
          console.log(price.hasIndividualDiscount, price.oldPrice, price.price);
          if (price.hasIndividualDiscount) {
            discount = price.oldPrice - price.price;
          }
        } else if (this.isDistributor) {
          discount = clientPrice.price - price.price;
        } else {
          sum += (clientPrice.price - price.price) * basketProduct.select_count;
          return;
        }

        sum += discount * basketProduct.select_count;
      });

      return sum;
    },
    getTotalPromocodeDiscount() {
      let total = 0;
      this.basket.forEach(basketProduct => {
        total += basketProduct.discount * basketProduct.select_count;
      });
      return total;
    },
    getTotalToPay() {
      const hasFreeDelivery =
        this.selectedDelivery.type === DeliveryType.PICKUP ||
        (this.selectedDelivery.freeAfter !== 0 && this.totalSum >= this.selectedDelivery.freeAfter);

      let deliveryPrice = parseInt(this.selectedDelivery.price);
      if (hasFreeDelivery) {
        deliveryPrice = 0;
      }

      /*auth user & first order*/
      let certValue = 0;
      if (this.certificate) {
        certValue = Math.min(this.certificate.discount, this.totalSum);
      }

      const totalAndCert = this.totalSum - certValue;

      if (this.selectedDelivery.type === DeliveryType.COURIER && totalAndCert > 10000) {
        return totalAndCert;
      }
      return totalAndCert + deliveryPrice;
    },
  },
  watch: {
    isOnlyProducts(newVal) {
      console.log(newVal, this.selectedDelivery.availablePaymentMethods);
      if (!newVal) {
        this.selectedPayment = this.selectedDelivery.availablePaymentMethods.filter(item => item.type !== "online")[0];
      }
    },
    bonusesAmount() {
      this.setBonuses({ pay_bonus_amount: this.bonusesAmount });
      this.$refs.bonusesError.innerHTML = "";
      this.disabledButton = false;

      if (this.bonusesAmount > this.user?.bonusAmount) {
        this.$refs.bonusesError.innerHTML = `доступно ${this.user.bonusAmount} бонусів`;
        this.disabledButton = true;
      }
    },
    selectedDelivery() {
      this.resetSelectedAddress();
      this.selectedAddressFromList = false;
      this.changeSelectedPayment(this.selectedDocument);
    },
    dataFromAddressesList() {
      this.selectedAddressFromList = !!(this.dataFromAddressesList.guid && this.isAuthenticated);
    },
    user() {
      this.setUserInfo();

      this.hidePromocod = false;

      if (this.isDistributor) {
        this.selectedDocument = OrderType.TRANSFER;
      }
    },
    "addressNovaPoshta.town": function () {
      if (this.addressNovaPoshta.town.length) {
        this.deliveryTown = true;
      }
    },
    transactionResult: function () {
      this.liqpay = this.transactionResult;
    },
    "payload.recipientName": function () {
      if (this.payload.recipientName.length > 0) {
        this.$refs.recipientName.textContent = "";
        this.recipientNameError = false;
      }
    },
    "payload.recipientPhone": function () {
      if (this.payload.recipientPhone.length > 0) {
        this.$refs.recipientPhone.textContent = "";
        this.recipientPhoneError = false;
      }
    },
    "payload.recipientSecondName": function () {
      if (this.payload.recipientSecondName.length > 0) {
        this.$refs.recipientSecondName.textContent = "";
        this.recipientSecondNameError = false;
      }
    },
    "organizationData.edrpouCode": function (val, oldVal) {
      if (val.length > 0 && !val.match(/^\d+$/)) {
        this.organizationData.edrpouCode = oldVal;
      }
    },
    $route(newVal) {
      this.randomHash = this.makeid(20);
      if (newVal) {
        if (!this.user && this.isAuthenticated) {
          this.fetchUser();
        }
        this.fetchDeliveryMethods().then(() => {
          this.selectedDelivery = this.deliveryMethods[0];
        });
      }
    },
    selectedDocument(val) {
      this.changeSelectedPayment(val);
    },
    organization() {
      this.clearUserInfoValidation();
    },
  },
  created() {
    this.resetCertificate();
    if (this.isAuthenticated && this.user) {
      this.setUserInfo();
    }
    this.fetchDeliveryMethods().then(() => {
      this.selectedDelivery = this.deliveryMethods[0];
    });
    this.randomHash = this.makeid(20);
    this.syncBasket();
    this.checkOrderGift();
    this.getClientPrices();
    fbq("track", "InitiateCheckout", {
      value: this.basket.reduce((accumulator, item) => {
        return (accumulator += item.select_type.price * item.select_count);
      }, 0),
      currency: "UAH",
      content_type: "product",
      content_ids: this.basket.map(basketProduct => {
        return basketProduct.unauthorizedProductPriceId;
      }),
      contents: this.basket.map(item => {
        return {
          content_name: item.title,
          id: item.unauthorizedProductPriceId,
          quantity: item.select_count,
          item_price: item.select_type.price,
        };
      }),
      num_items: this.basket.length,
    });
  },
  methods: {
    getProductPriceTitle,
    routeToProduct,
    resolveProductImage,
    makeid,
    ...mapActions({
      fetchAddress: "order/GET_ADDRESS_LIST",
      fetchDeliveryMethods: "order/GET_DELIVERY_METHODS",
      fetchUser: "profile/FETCH_USER_DATA",
      createOrder: "order/CREATE_ORDER",
      checkOrder: "order/CHECK_ORDER",
      getSyncBasket: "basket/GET_SYNC_BASKET",
      fetchDefaultPrices: "basket/GET_DEFAULT_PRICE_BASKET",
      fetchCertificate: "order/GET_CERTIFICATE",
      createInvoice: "order/CREATE_INVOICE",
      createTransaction: "order/CREATE_TRANSACTION",
      fetchPromocodeInfo: "basket/FETCH_PROMOCODE_INFO",
      checkGift: "order/CHECK_GIFT",
      getClientPriceBasket: "basket/GET_CLIENT_PRICE_BASKET",
    }),
    ...mapMutations({
      changeTextModal: "popups/SHOW_TEXT_MODAL",
      setBonuses: "basket/SET_USER_BONUSES",
      saveAddress: "order/SAVE_SELECT_ADDRESS",
      changeAddressesListPopup: "popups/CHANGE_ADDRESSES_LIST_POPUP",
      resetBasket: "basket/RESET_BASKET",
      resetCertificate: "order/CLEAR_CERTIFICATE",
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
      changeSuccessPayment: "popups/SHOW_SUCCESS_PAYMENT_POPUP",
      showRemainigsPopup: "popups/CHANGE_REMAININGS_POPUP",
      increment: "basket/INCREMENT_BASKET_LIST_COUNT",
      decrement: "basket/DECREMENT_BASKET_LIST_COUNT",
      setCount: "basket/SET_BASKET_LIST_COUNT",
      remove: "basket/REMOVE_ITEM_FROM_BASKET",
    }),

    showDisableModalPopup(payment) {
      this.changeTextModal({
        status: true,
        data: {
          detail: payment.unableText,
          width: 570,
        },
      });
    },

    async changeCount(type, product, count = 1) {
      if (type === "increment") {
        this.increment(product);
      } else if (type === "decrement") {
        this.decrement(product);
      } else {
        if (count) {
          this.setCount({
            product: product,
            select_count: parseInt(count),
          });
        }
      }
      await this.checkOrderGift();
    },
    resetSelectedAddress() {
      this.saveAddress({});
    },
    setUserInfo() {
      this.info.firstName = this.user.name;
      this.info.lastName = this.user.secondName;
      this.info.phone = this.user.phone.replace(/[^+\d]/g, "");
      this.info.email = this.user.email;
    },
    showPatronymicField() {
      return [DeliveryType.ADDRESS].includes(this.selectedDelivery.type);
    },
    /**
     *
     * @param {DeliveryMethod|any} delivery
     * @return {boolean}
     */
    canSelectAddress(delivery) {
      const showAddressSelect = [DeliveryType.ADDRESS, DeliveryType.COURIER, DeliveryType.STORAGE];

      return showAddressSelect.includes(delivery?.type);
    },
    /**
     * @param {DeliveryMethod} delivery
     */
    selectDeliveryMethod(delivery) {
      this.selectedDelivery = delivery;
      this.resetSelectedAddress();

      this.askUserAddress(delivery);
    },
    changeSelectedPayment(orderType) {
      if (!this.isOnlyProducts) {
        this.selectedPayment = this.selectedDelivery.availablePaymentMethods.filter(item => item.type !== "online")[0];
        console.log("this.selectedPayment", this.selectedPayment);
      } else {
        if (!this.isDistributor) {
          this.selectedPayment = this.selectedDelivery.availablePaymentMethods.find(item => item.unable === false);
          console.log(this.selectedPayment);
        } else {
          if ([OrderType.CLIENTSALE, OrderType.SALE].includes(orderType)) {
            this.selectedPayment = this.selectedDelivery.availablePaymentMethods.find(
              method => method.processType === "pay_with_details"
            );
          } else if (OrderType.TRANSFER === orderType) {
            this.selectedPayment = null;
          }
        }
      }
    },

    /**
     * @param {DeliveryMethod} delivery
     */
    askUserAddress(delivery) {
      if (this.loadingAddresses) return;
      this.loadingAddresses = true;
      if (!this.canSelectAddress(delivery)) {
        this.loadingAddresses = false;
        return;
      }

      this.fetchAddress()
        .then(() => {
          if (this.deliveryAddresses.length) {
            this.changeAddressesListPopup(true);
          }
        })
        .finally(() => {
          this.loadingAddresses = false;
        });
    },
    async handleRemove(item) {
      this.remove(item);
      await this.checkOrderGift();
    },
    checkCode(code) {
      this.fetchCertificate(code)
        .then(() => {
          this.$toasted.success(this.$t("promocodeActivate"));
          if (
            ((this.selectedDelivery.type === DeliveryType.STORAGE && this.totalSum >= 1500) ||
              (this.selectedDelivery.type === DeliveryType.ADDRESS && this.totalSum >= 3000) ||
              this.selectedDelivery.type === DeliveryType.PICKUP) &&
            this.certificate.discount === this.totalSum
          ) {
            this.certificateFlag = true;
          } else {
            this.certificateFlag = false;
          }
        })
        .catch(() => {
          this.$toasted.error(this.$t("errorPromocode"));
        });
    },
    async syncBasket() {
      const ids = map(this.basket, "id");
      await this.getSyncBasket({ ids: ids });
      await this.fetchDefaultPrices({ ids: ids });
    },

    async checkOrderGift() {
      try {
        let products = {};
        this.basket.map(item => {
          products[item.select_type.id] = item.select_count;
        });

        const formattedProducts = Object.fromEntries(
          Object.entries(products).map(([key, value]) => [`products[${key}]`, value])
        );

        await Promise.all([this.checkGift(formattedProducts)]);
      } catch (e) {
        console.error(e);
      }
    },

    async getClientPrices() {
      let basketIds = [];
      basketIds = this.basket.map(item => item.id);
      await this.getClientPriceBasket({ ids: basketIds });
    },

    async createInvoiceLocal() {
      try {
        await this.createInvoice({ order_id: this.orderResult.id });
        await this.createTransactionLocal();
      } catch (error) {
        throw error;
      }
    },
    async createTransactionLocal() {
      try {
        await this.createTransaction({
          invoice_id: this.invoiceResult.id,
        });

        setTimeout(() => {
          document.getElementById("checkout-form").submit();
        }, 1500);
      } catch (error) {
        throw error;
      }
    },
    clearUserInfoValidation() {
      const userInfo = document.querySelector(".order-contact__form");
      const inputs = userInfo.querySelectorAll(".input-col");
      inputs.forEach(item => {
        if (item.classList.contains("error")) {
          item.classList.remove("error");
          item.classList.add("valid");
          const label = item.querySelector("span");
          label.innerHTML = "";
        }
      });
    },
    validateRecipient() {
      if (this.selectedRecipient.id === RecipientType.ME) this.submit();
      if (this.selectedRecipient.id === RecipientType.ANOTHER) this.validationRecipientData();
    },
    validationRecipientData() {
      this.validationPhone();
      this.validationName();
      this.validationSecondName();
      if (this.recipientPhoneError || this.recipientNameError || this.recipientSecondNameError) {
        return;
      }
      this.submit();
    },
    validationPhone() {
      let error = this.$refs.recipientPhone;
      let phone = this.payload.recipientPhone.length;
      error.textContent = "";
      switch (true) {
        case phone === 0:
          error.append(this.$t("validationRequired"));
          this.recipientPhoneError = true;
          break;
        case phone < 16 && phone > 0:
          error.append(this.$t("validationMin").replace(":count", 12));
          this.recipientPhoneError = true;
          break;
      }
    },
    validationName() {
      let error = this.$refs.recipientName;
      let phone = this.payload.recipientName.length;
      error.textContent = "";
      switch (true) {
        case phone === 0:
          error.append(this.$t("validationRequired"));
          this.recipientNameError = true;
          break;
        case phone < 2 && phone > 0:
          error.append(this.$t("validationMin").replace(":count", 2));
          this.recipientNameError = true;
          break;
      }
    },
    validationSecondName() {
      let error = this.$refs.recipientSecondName;
      let phone = this.payload.recipientSecondName.length;
      error.textContent = "";
      switch (true) {
        case phone === 0:
          error.append(this.$t("validationRequired"));
          this.recipientSecondNameError = true;
          break;
        case phone < 2 && phone > 0:
          error.append(this.$t("validationMin").replace(":count", 2));
          this.recipientSecondNameError = true;
          break;
      }
    },
    validationCompanyCourier() {
      let departmentError = document.getElementById("addressError");
      let buildingError = document.getElementById("buildingError");
      let flatError = document.getElementById("flatError");

      if (!this.selectedAddressFromList) {
        if (!this.deliveryAddress.department) {
          departmentError.innerHTML = this.$t("validationRequired");
        }
        if (!this.deliveryAddress.house) {
          buildingError.innerHTML = this.$t("validationRequired");
        }
        if (!this.deliveryAddress.flat) {
          flatError.innerHTML = this.$t("validationRequired");
        }

        if (this.deliveryAddress.department && this.deliveryAddress.flat && this.deliveryAddress.house) {
          departmentError.innerHTML = "";
          buildingError.innerHTML = "";
          flatError.innerHTML = "";

          this.validateRecipient();
        }
      }

      if (this.selectedAddressFromList) {
        this.validateRecipient();
      }
    },
    validationDepartmentCourier() {
      let cityError = document.getElementById("courierCityError");
      let departmentError = document.getElementById("addressError");
      let buildingError = document.getElementById("buildingError");
      let flatError = document.getElementById("flatError");

      if (!this.selectedAddressFromList) {
        if (!this.deliveryAddress.city) {
          cityError.innerHTML = this.$t("validationRequired");
        }
        if (!this.deliveryAddress.department) {
          departmentError.innerHTML = this.$t("validationRequired");
        }
        if (!this.deliveryAddress.house) {
          buildingError.innerHTML = this.$t("validationRequired");
        }
        if (!this.deliveryAddress.flat) {
          flatError.innerHTML = this.$t("validationRequired");
        }

        if (
          this.deliveryAddress.city &&
          this.deliveryAddress.department &&
          this.deliveryAddress.house &&
          this.deliveryAddress.flat
        ) {
          cityError.innerHTML = "";
          departmentError.innerHTML = "";
          buildingError.innerHTML = "";
          flatError.innerHTML = "";

          this.validateRecipient();
        }
      }

      if (this.selectedAddressFromList) {
        this.validateRecipient();
      }
    },
    validationDepartmentDelivery() {
      let cityError = document.getElementById("cityError");
      let departmentError = document.getElementById("departmentError");

      if (!this.selectedAddressFromList) {
        if (!this.deliveryAddress.city) {
          cityError.innerHTML = this.$t("validationRequired");
        }
        if (!this.deliveryAddress.department) {
          departmentError.innerHTML = this.$t("validationRequired");
        }

        if (this.deliveryAddress.city && this.deliveryAddress.department) {
          cityError.innerHTML = "";
          departmentError.innerHTML = "";

          this.validateRecipient();
        }
      }

      if (this.selectedAddressFromList) {
        this.validateRecipient();
      }
    },
    validateDeliveryMethod() {
      switch (this.selectedDelivery.type) {
        case DeliveryType.PICKUP:
          this.validateRecipient();
          break;
        case DeliveryType.COURIER:
          if (this.savedAddress.address !== "") {
            this.validationCompanyCourier();
          } else {
            this.changeAddressesListPopup(true);
          }
          break;
        case DeliveryType.ADDRESS:
          if (this.savedAddress.address !== "") {
            this.validationDepartmentCourier();
          } else {
            this.changeAddressesListPopup(true);
          }
          break;
        case DeliveryType.STORAGE:
          if (this.savedAddress.address !== "") {
            this.validationDepartmentDelivery();
          } else {
            this.changeAddressesListPopup(true);
          }
          break;
      }
    },

    validateOrgModePaymentAndDelivery() {
      if (this.selectedRecipient.id === RecipientType.ANOTHER) {
        this.validationPhone();
        this.validationName();
        this.validationSecondName();
      }
      if (
        this.selectedPayment &&
        this.selectedPayment.type === "online" &&
        this.selectedDelivery.type === DeliveryType.PICKUP &&
        this.selectedRecipient.id === 0
      )
        return true;

      let deliveryError = false;
      switch (this.selectedDelivery.type) {
        case DeliveryType.COURIER:
          if (this.savedAddress.address !== "") {
            let departmentError = document.getElementById("addressError");
            let buildingError = document.getElementById("buildingError");
            let flatError = document.getElementById("flatError");

            if (!this.selectedAddressFromList) {
              if (!this.deliveryAddress.department) {
                departmentError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }
              if (!this.deliveryAddress.house) {
                buildingError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }
              if (!this.deliveryAddress.flat) {
                flatError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }

              if (this.deliveryAddress.department && this.deliveryAddress.flat && this.deliveryAddress.house) {
                departmentError.innerHTML = "";
                buildingError.innerHTML = "";
                flatError.innerHTML = "";
              }
            }
          } else {
            this.changeAddressesListPopup(true);
          }
          break;
        case DeliveryType.ADDRESS:
          if (this.savedAddress.address !== "") {
            let cityError = document.getElementById("courierCityError");
            let departmentError = document.getElementById("addressError");
            let buildingError = document.getElementById("buildingError");
            let flatError = document.getElementById("flatError");

            if (!this.selectedAddressFromList) {
              if (!this.deliveryAddress.city) {
                cityError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }
              if (!this.deliveryAddress.department) {
                departmentError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }
              if (!this.deliveryAddress.house) {
                buildingError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }
              if (!this.deliveryAddress.flat) {
                flatError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }

              if (
                this.deliveryAddress.city &&
                this.deliveryAddress.department &&
                this.deliveryAddress.house &&
                this.deliveryAddress.flat
              ) {
                cityError.innerHTML = "";
                departmentError.innerHTML = "";
                buildingError.innerHTML = "";
                flatError.innerHTML = "";
              }
            }
          } else {
            this.changeAddressesListPopup(true);
          }
          break;
        case DeliveryType.STORAGE:
          if (this.savedAddress.address !== "") {
            let cityError = document.getElementById("cityError");
            let departmentError = document.getElementById("departmentError");

            if (!this.selectedAddressFromList) {
              if (!this.deliveryAddress.city) {
                cityError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }
              if (!this.deliveryAddress.department) {
                departmentError.innerHTML = this.$t("validationRequired");
                deliveryError = true;
              }

              if (this.deliveryAddress.city && this.deliveryAddress.department) {
                cityError.innerHTML = "";
                departmentError.innerHTML = "";
              }
            }
          } else {
            this.changeAddressesListPopup(true);
          }
          break;
      }
      return !(this.recipientPhoneError || this.recipientNameError || this.recipientSecondNameError || deliveryError);
    },

    checkCorrectInfo(field) {
      return this.$v[field] ? this.$v[field].$invalid === false || this.$v[field].$invalid === undefined : true;
    },

    validateDistributor() {
      this.$v.$touch();
      let correct = false;
      if (!this.checkCorrectInfo("info")) correct = true;
      if (!this.checkCorrectInfo("organizationData")) correct = true;

      if (this.selectedDocument === OrderType.TRANSFER) {
        if (this.validateOrgModePaymentAndDelivery()) correct = true;
      }

      if (this.selectedDocument === OrderType.CLIENTSALE) {
        if (this.validateOrgModePaymentAndDelivery()) correct = true;
      }

      if (this.selectedDocument === OrderType.SALE) {
        if (this.validateOrgModePaymentAndDelivery()) correct = true;
      }
      if (correct) {
        this.submit();
      } else {
        console.log("Error");
      }
    },

    hasTempDiscount() {
      return this.user && this.user.tempDiscount;
    },
    canSeeDiscount() {
      return this.user && (this.isUserWithCode || this.isProfessional || this.isDistributor);
    },
    /**
     * @param {DeliveryMethod} delivery
     * @return {string|null}
     */
    getTitleForDeliveryInList(delivery) {
      if (!delivery) {
        return null;
      }

      if (delivery.type === DeliveryType.PICKUP) return this.$t("free");
      if (delivery.type === DeliveryType.STORAGE) return this.$t("deliveries.accordingToTheCarrierTariffs");
      if (delivery.type === DeliveryType.ADDRESS) return this.$t("deliveries.accordingToTheCarrierTariffs");
      if (delivery.type === DeliveryType.COURIER) return "100 грн";

      if (delivery.price <= 0) return "";

      return delivery.price + " " + this.$t("grn");
    },
    /**
     *
     * @param {DeliveryMethod} delivery
     * @return {string|null}
     */
    getDeliveryPriceToDisplay(delivery) {
      if (delivery.type === DeliveryType.PICKUP) return this.$t("free");
      if (delivery.type === DeliveryType.STORAGE) return this.$t("deliveries.accordingToTheCarrierTariffs");
      if (this.promocodeResponse) {
        if (delivery.type === DeliveryType.COURIER && this.promocodeResponse.order.totalAmount < 10000)
          return delivery.price + " " + this.$t("grn");
        if (delivery.type === DeliveryType.COURIER && this.promocodeResponse.order.totalAmount > 10000)
          return this.$t("free");
      } else {
        if (delivery.type === DeliveryType.COURIER && this.getTotalToPay < 10000)
          return delivery.price + " " + this.$t("grn");
        if (delivery.type === DeliveryType.COURIER && this.getTotalToPay > 10000) return this.$t("free");
      }

      const deliveries = [DeliveryType.PICKUP, DeliveryType.STORAGE, DeliveryType.COURIER];
      if (!deliveries.includes(delivery.type)) return this.$t("deliveries.accordingToTheCarrierTariffs");

      if (delivery.price <= 0) return "";

      return delivery.price + " " + this.$t("grn");
    },
    sendOrder() {
      if (this.isDistributor) {
        this.validateDistributor();
      } else {
        if (this.isOnlyProducts) {
          this.validateDeliveryMethod();
        } else {
          this.submit();
        }
      }
    },
    generateOrderPayload(createOrderPayload, promocode = false) {
      if (this.promocode) createOrderPayload["promocode"] = this.promocode;
      if (this.selectedBonuses.pay_bonus_amount)
        createOrderPayload.pay_bonus_amount = this.selectedBonuses.pay_bonus_amount;
      if (this.promocodeResponse) createOrderPayload["order_id"] = this.promocodeResponse.order.id;
      if (this.isDistributor) {
        if (this.organization) {
          createOrderPayload.contact_legal_type = "organisation";
          createOrderPayload.organisation_edrpo_code = this.organizationData.edrpouCode;
          createOrderPayload.organisation_name = this.organizationData.companyName;
          createOrderPayload.organisation_ownership = this.organizationData.ownershipType;
        } else {
          createOrderPayload.contact_legal_type = "private";
        }
      }

      createOrderPayload.products = map(this.basket, product => ({
        id: product.id,
        product_price_id: product.select_type.id,
        count: product.select_count,
      }));

      if (this.promocodeResponse) {
        if (
          this.selectedDelivery.type === DeliveryType.COURIER &&
          this.promocodeResponse.order.totalAmount < 10000 &&
          !promocode
        ) {
          const selectedType = this.courierProduct.productPrices.data.find(item => item.priceType === "uah");
          createOrderPayload.products.push({
            id: this.courierProduct.id,
            product_price_id: selectedType.id,
            count: 1,
          });
        }
      } else {
        if (this.selectedDelivery.type === DeliveryType.COURIER && this.getTotalToPay < 10000 && !promocode) {
          const selectedType = this.courierProduct.productPrices.data.find(item => item.priceType === "uah");
          createOrderPayload.products.push({
            id: this.courierProduct.id,
            product_price_id: selectedType.id,
            count: 1,
          });
        }
      }

      // user info
      createOrderPayload.first_name = this.info.firstName;
      createOrderPayload.last_name = this.info.lastName;
      createOrderPayload.phone = this.info.phone;
      createOrderPayload.email = this.info.email;
      createOrderPayload.additional_information = this.info.additionalInformation;
      createOrderPayload.patronymic = this.info.patronymic;

      createOrderPayload.phone = createOrderPayload.phone.replace(/[^+\d]/g, "");

      // payment
      if (this.certificate) {
        createOrderPayload.certificate = this.selectedCertificate;
        if (!this.certificateFlag) {
          createOrderPayload.payment_method_id = this.selectedPayment.id;
        }
      } else {
        if (this.selectedDocument !== OrderType.TRANSFER) {
          createOrderPayload.payment_method_id = this.selectedPayment.id;
        }
      }

      // delivery
      if (!this.isDistributor || this.selectedDocument !== OrderType.CLIENTSALE) {
        createOrderPayload.delivery_method_id = this.selectedDelivery?.id;
      }

      createOrderPayload.recipient = !!this.selectedRecipient.id;
      createOrderPayload.recipient_first_name = this.payload.recipientName;
      createOrderPayload.recipient_last_name = this.payload.recipientSecondName;
      createOrderPayload.recipient_phone = this.payload.recipientPhone;
      createOrderPayload.need_confirm = !this.do_not_callback;

      if (this.isProfessional || this.isDistributor) {
        createOrderPayload.only_webinars = this.isOnlyWebinars;
        createOrderPayload.goes_to_patient = this.goes_to_patient;
      }
      if (this.canSelectAddress(this.selectedDelivery)) {
        if (this.selectedAddressFromList) {
          createOrderPayload.address = {
            guid: this.dataFromAddressesList.guid,
            contact: this.dataFromAddressesList.contact,
          };
        } else {
          const needFullAddress = [DeliveryType.ADDRESS, DeliveryType.COURIER];

          const setAddressDetails = () => {
            if (!promocode) {
              createOrderPayload.delivery_city =
                this.addressNovaPoshtaCourier.city || this.deliveryAddress.city.Description;
              createOrderPayload.delivery_user_address = [
                `м. ${createOrderPayload.delivery_city}`,
                `вул. ${this.addressNovaPoshtaCourier.department}`,
                `буд. ${this.addressNovaPoshtaCourier.building}`,
                `кв. ${this.addressNovaPoshtaCourier.flat}`,
              ].join(", ");
            }
          };

          const setStoreDetails = () => {
            if (!promocode) {
              createOrderPayload.delivery_city = this.addressNovaPoshta.city || this.deliveryAddress.city.Description;
              createOrderPayload.delivery_store =
                this.addressNovaPoshta.department || this.deliveryAddress.department.Description;
              createOrderPayload.delivery_user_address = [
                `м. ${createOrderPayload.delivery_city}`,
                `${createOrderPayload.delivery_store}`,
              ].join(", ");
            }
          };

          if (!this.isDistributor || this.selectedDocument !== OrderType.CLIENTSALE) {
            if (needFullAddress.includes(this.selectedDelivery.type)) {
              setAddressDetails();
            } else if (this.selectedDelivery.type === DeliveryType.STORAGE && this.isOnlyProducts) {
              setStoreDetails();
            }
          }
        }
      }
    },
    async submit() {
      this.$v.$touch();
      if (this.organization) {
        const edrpou = !!this.organizationData.edrpouCode;
        const ownershipType = !!this.organizationData.ownershipType;
        const companyName = !!this.organizationData.companyName;

        if (!edrpou || !ownershipType || !companyName) {
          console.log("Error");
          return;
        }
      }
      if (this.user?.bonusAmount < this.bonusesAmount) {
        return;
      }

      if (this.$v.info.$invalid) {
        return;
      }

      /** @type {OrderCreatePayload} */
      const createOrderPayload = {
        hash: this.randomHash,
        order_type: this.selectedDocument,
      };
      this.generateOrderPayload(createOrderPayload);

      // for mocking order sending
      // this.showPopup();
      // console.log('order', createOrderPayload);
      // return;
      try {
        await this.checkOrder(createOrderPayload);
        if (!this.orderCheckResult.result) {
          this.showRemainigsPopup(true);
          return;
        }
        await this.createOrder(createOrderPayload);
        const findProduct = productId => {
          return this.basketResponse.find(({ id }) => id === productId);
        };
        console.log(this.basket);
        fbq("track", "Purchase", {
          value: this.basket.reduce((accumulator, item) => {
            return (accumulator += item.select_type.price * item.select_count);
          }, 0),
          currency: "UAH",
          content_type: "product",
          content_ids: this.basket.map(({ client_price, select_type, id }) => {
            if (select_type && select_type.id) return select_type.id;
            if (client_price && client_price.id) return client_price.id;
            return id;
          }),
          contents: this.basket.map(item => {
            return {
              title: item.title,
              id: item.select_type.id,
              quantity: item.select_count,
              item_price: item.select_type.price,
              content_category:
                findProduct(item.id)?.brandSeries?.title || findProduct(item.id)?.brand?.title || item.title,
            };
          }),
          num_items: this.basket.length,
        });

        if (this.selectedPayment && this.selectedPayment.processType === PaymentProcessType.CARD_ONLINE) {
          await this.createInvoiceLocal();
          await this.resetBasket();

          return;
        }
      } catch (error) {
        console.error(error);
        if (error.status === 422) {
          const objErrors = error.data.errors;
          for (let i in objErrors) {
            objErrors[i].forEach(t => {
              this.$toasted.error(t);
            });
          }
        } else {
          this.$toasted.error(error.data.message);
        }

        return;
      }

      this.finishOrder();
    },
    finishOrder() {
      const handle = () => {
        this.showPopup();
        this.resetBasket();
      };
      this.$router
        .push({ name: "main" })
        .then(() => setTimeout(handle, 1500))
        .catch(() => setTimeout(handle, 1500));

      this.$toasted.success(this.$t("successCreateOrder"));
    },
    async activatePromocode() {
      this.loadingPromoCode = true;
      try {
        /** @type {OrderCreatePayload} */
        const orderPayload = {
          hash: this.randomHash,
          order_type: this.selectedDocument,
        };
        this.generateOrderPayload(orderPayload, true);

        const ids = map(this.basket, "id");
        const response = await this.fetchPromocodeInfo(orderPayload);
        if (response.promocode.activated) {
          this.promocodeActivated = response.promocode.activated;
          this.promocodeProducts = response.order.orderProducts.data;
          this.promocodeResponse = response;
          this.$toasted.success("Промокод успішно активовано");
        } else {
          this.promocodeActivated = response.promocode.activated;
          this.$toasted.error("Недійсний промокод");
        }
        await this.fetchDefaultPrices({ ids: ids });
      } finally {
        this.loadingPromoCode = false;
      }
    },
    showPopup() {
      const popupSettings = {
        withSms: this.selectedPayment?.processType === PaymentProcessType.PAY_WITH_DETAILS,
        notCall: this.do_not_callback,
        withWebinar: this.isAwebinar,
      };

      if (this.isDistributor) {
        popupSettings.documentType = this.selectedDocument;
      }

      this.changeSuccessPayment(true, popupSettings);
    },
  },
};

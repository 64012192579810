interface State {
	showSuccessPaymentPopup: { status: boolean, payload: any };
	showRemainingsPopup: boolean;
	loginPopup: any;
	loginManagerPopup: boolean;
	registrationPopup: any;
	resetPopup: any;
	restorePopup: any;
	addressesListPopup: any;
	instagramPopup: any;
	successRegisterPopup: any;
	anotherCosmetolog: any;
	fastBuyPopup: any;
	showDefaultPrice: any;
	formApplicationPopup: any;
	paymentInfoPopup: any;
	successRegisterAgent: any;
	seminarDetails: any;
	shoeNotifyWhenAvailable: any;
	showSeminarInfoPopup: boolean;
	showSeminarInfoContent: {
		title: string | null;
		description: string | null;
	};
	seminarVideoModalStatus: boolean,
	seminarVideoModalUrl: string,
	showSeminarProfessionalOnlyPopup: boolean,
	reviewModalValue: any,
}

const state: State = {
	seminarDetails: {
		status: false,
		data: "",
	},
	shoeNotifyWhenAvailable: {
		status: false,
		data: "",
	},
	showSuccessPaymentPopup: {
		status: false,
		payload: { withSms: false, notCall: false, withWebinar: false },
	},
	showRemainingsPopup: false,
	loginPopup: false,
	loginManagerPopup: false,
	registrationPopup: false,
	resetPopup: false,
	restorePopup: false,
	addressesListPopup: false,
	instagramPopup: false,
	showDefaultPrice: false,
	formApplicationPopup: false,
	paymentInfoPopup: false,
	successRegisterPopup: {
		show: false,
		text: "",
		code: "",
		percent: null,
	},
	anotherCosmetolog: false,
	fastBuyPopup: {
		show: false,
		product: null,
	},
	successRegisterAgent: false,
	showSeminarInfoPopup: false,
	showSeminarInfoContent: {
		title: null,
		description: null,
	},
	seminarVideoModalStatus: false,
	seminarVideoModalUrl: "",
	showSeminarProfessionalOnlyPopup: false,
	reviewModalValue: null,
};

const getters = {
	shoeNotifyWhenAvailable: (state: State) => state.shoeNotifyWhenAvailable,
	showSuccessPaymentPopup: (state: State) => state.showSuccessPaymentPopup,
	loginPopup: (state: State) => state.loginPopup,
	loginManagerPopup: (state: State) => state.loginManagerPopup,
	registrationPopup: (state: State) => state.registrationPopup,
	resetPopup: (state: State) => state.resetPopup,
	restorePopup: (state: State) => state.restorePopup,
	addressesListPopup: (state: State) => state.addressesListPopup,
	instagramPopup: (state: State) => state.instagramPopup,
	successRegisterPopup: (state: State) => state.successRegisterPopup,
	anotherCosmetolog: (state: State) => state.anotherCosmetolog,
	fastBuyPopup: (state: State) => state.fastBuyPopup,
	showDefaultPrice: (state: State) => state.showDefaultPrice,
	formApplicationPopup: (state: State) => state.formApplicationPopup,
	paymentInfoPopup: (state: State) => state.paymentInfoPopup,
	successRegisterAgent: (state: State) => state.successRegisterAgent,
	seminarDetails: (state: State) => state.seminarDetails,
	showRemainingsPopup: (state: State) => state.showRemainingsPopup,
	showSeminarInfoPopup: (state: State) => state.showSeminarInfoPopup,
	showSeminarInfoContent: (state: State) => state.showSeminarInfoContent,
	seminarVideoModalStatus: (state: State) => state.seminarVideoModalStatus,
	seminarVideoModalUrl: (state: State) => state.seminarVideoModalUrl,
	showSeminarProfessionalOnlyPopup: (state: State) => state.showSeminarProfessionalOnlyPopup,
	reviewModalPayload: (state: State) => state.reviewModalValue.payload,
	reviewModalStatus: (state: State) => state.reviewModalValue?.status || false,
};

const actions = {};

const mutations = {
	CHANGE_DEFAULT_PRICES(state: State, status: any) {
		state.showDefaultPrice = status;
	},
	CHANGE_FAST_BUY_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.fastBuyPopup = status;
	},
	CHANGE_LOGIN_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.loginPopup = status;
	},
	CHANGE_LOGIN_MANAGER_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.loginManagerPopup = status;
	},
	CHANGE_ADDRESSES_LIST_POPUP(state: State, status: any) {
		if (status === state.addressesListPopup) return;
		document.getElementById("html")?.classList.remove("hide");
		state.addressesListPopup = status;
	},
	CHANGE_REGISTRATION_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.registrationPopup = status;
	},
	CHANGE_RESET_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.resetPopup = status;
	},
	CHANGE_RESTORE_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.restorePopup = status;
	},
	CHANGE_INSTAGRAM_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.instagramPopup = status;
	},
	CHANGE_SUCCESS_REGISTER_POPUP(state: State, data: any) {
		document.getElementById("html")?.classList.remove("hide");
		Object.assign(state.successRegisterPopup, data);
	},
	CHANGE_COSMETOLOG_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.anotherCosmetolog = status;
	},
	CHANGE_APPLICATION_FORM_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.formApplicationPopup = status;
	},
	CHANGE_PAYMENT_INFO_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.paymentInfoPopup = status;
	},
	CHANGE_SHOW_SUCCESS_AGENT_REGISTER(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.successRegisterAgent = status;
	},
	CHANGE_REMAININGS_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.showRemainingsPopup = status;
	},
	SHOW_SEMINAR_INFO_POPUP(state: State, status: any) {
		document.getElementById("html")?.classList.remove("hide");
		state.showSeminarInfoPopup = status;
	},
	SET_SEMINAR_INFO_CONTENT(state: State, data: { title: string | null; description: string | null }) {
		state.showSeminarInfoContent = data;
	},
	SHOW_SUCCESS_PAYMENT_POPUP(state: State, status: boolean, payload: any = {}) {
		state.showSuccessPaymentPopup.status = status;
		state.showSuccessPaymentPopup.payload = payload;
	},
	SHOW_SEMINAR_DETAILS_POPUP(state: State, data: any) {
		state.seminarDetails = data;
	},
	SHOW_TEXT_MODAL(state: State, data: any) {
		state.seminarDetails = data;
	},
	SHOW_NOTIFY_WHEN_AVAILABLE(state: State, data: any) {
		state.shoeNotifyWhenAvailable = data;
	},
	SHOW_SEMINAR_VIDEO_MODAL(state: State, data: any) {
		state.seminarVideoModalUrl = data.link;
		state.seminarVideoModalStatus = data.status;
	},
	SHOW_SEMINAR_PROFESSIONAL_ONLY_POPUP(state: State, status: boolean) {
		state.showSeminarProfessionalOnlyPopup = status;
	},
	CHANGE_REVIEW_MODAL(state: State, payload: any = {}) {
		state.reviewModalValue = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

import product from "@/components/product/index.vue";
import groupProduct from "@/components/group-product/index.vue";
import categoryFilter from "./components/category-filter/index.vue";
import sortSelect from "./components/sort-select/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import { directive as onClickaway } from "vue-clickaway";
import _ from "lodash";

export default {
  name: "catalog",
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      loadingProducts: false,
      loadingFilters: false,
      products: [],
      filters: [],
      openFilter: false,
      storedCatalogType: null,
      paginator: {
        hasNextPage: false,
        page: 1,
        perPage: 3 * 4, // load 4 lines per 3 product
      },
    };
  },
  components: {
    groupProduct,
    product,
    categoryFilter,
    sortSelect,
  },
  watch: {
    async $route() {
      if (this.storedCatalogType !== this.catalogType) {
        this.products = [];
        this.storedCatalogType = this.catalogType;
      }
      if (!this.loadingProducts) {
        await this.resetPage();
        await this.loadProducts();
      }
    },
  },
  created() {
    this.loadFilters();
    this.loadProducts();
    this.storedCatalogType = this.catalogType;
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      favourites: "favorites/whichList",
    }),
    productsList() {
      if (!this.isAuthenticated || !this.favourites) {
        return this.products;
      }

      const favourites = _.map(this.favourites, "productId");
      const markAsSelected = product => {
        product.isFavorite = favourites.includes(product.id);
        return product;
      };

      if (this.catalogType === "table") {
        return _.map(this.products, markAsSelected);
      }

      return this.products.map(productGroup => {
        _.map(productGroup.products, markAsSelected);

        return productGroup;
      });
    },
    catalogType() {
      if (this.$route.query.catalog_type === "groups") {
        return "groups";
      }

      return "table";
    },
  },
  methods: {
    ...mapActions({
      fetchProductsForCatalog: "products/GET_PRODUCTS_FOR_CATALOG",
      fetchCatalogFilters: "products/GET_CATALOG_FILTERS",
    }),
    ...mapMutations({}),
    test2() {},
    openFilters() {
      this.openFilter = !this.openFilter;
      if (this.openFilter) {
        document.getElementById("html").classList.add("hide");
      } else {
        document.getElementById("html").classList.remove("hide");
      }
    },
    openCategory(item) {
      return !!(item.isOpened ^ this.open);
    },
    changeSlideToggle() {
      this.open = !this.open;
    },
    resetFilter() {
      this.$router
        .push({
          name: "products",
          query: {
            catalog_type: this.$route.query.catalog_type,
          },
        })
        .catch(() => {});
    },
    away: function () {
      this.openFilter = !this.openFilter;
    },
    async resetPage() {
      this.paginator.page = 1;
      this.paginator.hasNextPage = false;
    },
    async loadFilters() {
      this.filters = await this.fetchCatalogFilters();
    },
    async loadProducts() {
      this.loadingProducts = true;

      let urlQuery = cloneDeep(this.$route.query);

      if (this.paginator.page === 1) {
        this.products = [];
      }

      /** @type {ICatalogFilter} */
      const catalogFilters = {
        catalog_type: urlQuery.catalog_type,
        sort_filter_type: urlQuery.sort_filter_type || "bestsellers",
        brands: urlQuery.brands,
        series: urlQuery.series,
        categories: urlQuery.categories,
        page: this.paginator.page,
        perPage: this.paginator.perPage,
      };
      const catalogData = await this.fetchProductsForCatalog(catalogFilters);

      this.products.push(...catalogData.data);

      this.paginator.hasNextPage = !!catalogData.links.next;

      this.loadingProducts = false;
    },
    async paginateProducts() {
      this.paginator.page += 1;

      await this.loadProducts();
    },
  },
};

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "metodist-popup",
  computed: {
    ...mapGetters({
      metodistVideo: "metodist/videoLink",
      metodistPopup: "metodist/popupOpen",
    }),
  },
  methods: {
    ...mapMutations({
      openMetodistPopup: "metodist/OPEN_METODIST_POPUP",
      closeMetodistPopup: "metodist/CLOSE_METODIST_POPUP",
    }),

    handleVideoEnd() {
      this.closeMetodistPopup();
    },
  },
};

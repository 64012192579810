import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "delivery-nova-poshta-company",
  data() {
    return {
      town: "Київ",
      townRef: "",
      department: "",
      isValid: false,
      isValidTown: false,
      isValidDepartment: false,
      building: "",
      flat: "",
    };
  },
  computed: {
    ...mapGetters({
      towns: "order/towns",
      departments: "order/departments",
      isTownsLoading: "order/isTownsLoading",
    }),
  },
  watch: {
    department() {
      const currentTown = this.towns.find(e => e.Description === this.town);
      const currentDepartment = this.departments.find(e => e.Description === this.department);
      this.checkValidData();
      this.validateDelivery();
      this.saveAddress({
        city: currentTown,
        department: currentDepartment,
        house: this.building,
        flat: this.flat,
      });
      this.changeAddress({
        town: this.town,
        department: this.department,
        building: this.building,
        flat: this.flat,
      });
    },
    building() {
      const currentTown = this.towns.find(e => e.Description === this.town);
      const currentDepartment = this.departments.find(e => e.Description === this.department);
      this.checkValidData();
      this.validateDelivery();
      this.saveAddress({
        city: currentTown,
        department: currentDepartment,
        house: this.building,
        flat: this.flat,
      });
    },
    flat() {
      const currentTown = this.towns.find(e => e.Description === this.town);
      const currentDepartment = this.departments.find(e => e.Description === this.department);
      this.checkValidData();
      this.validateDelivery();
      this.saveAddress({
        city: currentTown,
        department: currentDepartment,
        house: this.building,
        flat: this.flat,
      });
    },
  },
  async created() {
    await this.onRequestTown(this.town);
    this.onRequestDepartments("");
  },
  methods: {
    ...mapActions({
      fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
      fetchDepartments: "order/NOVA_POSHTA_GET_DEPARTMENS",
    }),
    ...mapMutations({
      setDepartments: "order/NOVA_POSHTA_SET_DEPARTMENS",
      changeAddress: "order/CHANGE_ADDRESS_COURIER",
      saveAddress: "order/SAVE_DELIVERY_ADDRESS",
    }),
    validateDelivery() {
      const currentDepartment = this.departments.find(e => e.Description === this.department);
      let departmentError = this.$refs.addressError;
      currentDepartment
        ? (departmentError.innerHTML = "")
        : (departmentError.innerHTML = this.$t("validationRequired"));

      let buildingError = this.$refs.buildingError;
      this.building.length ? (buildingError.innerHTML = "") : (buildingError.innerHTML = this.$t("validationRequired"));

      let flatError = this.$refs.flatError;
      this.flat.length ? (flatError.innerHTML = "") : (flatError.innerHTML = this.$t("validationRequired"));
    },
    onRequestTown(val) {
      const formData = JSON.stringify({
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: val,
          Language: "ua",
        },
        apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
      });
      this.fetchTowns(formData).then(() => {
        const currentTown = this.towns.find(e => e.Description === this.town);
        this.department = "";
        this.townRef = currentTown.Ref;
        if (!currentTown) {
          return;
        }

        if (this.town === currentTown.Description) {
          this.onRequestDepartments("");
        } else {
          this.setDepartments([]);
        }
        this.checkValidData();
      });
    },
    onRequestDepartments(val) {
      const formData = JSON.stringify({
        modelName: "Address",
        calledMethod: "getStreet",
        methodProperties: {
          CityRef: this.townRef,
          FindByString: val,
          Language: "ua",
        },
        apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
      });

      this.fetchDepartments(formData).then(() => {
        this.checkValidData();
      });
    },
    check() {},
    checkValidData() {
      const currentTown = this.towns.find(e => e.Description === this.town);
      if (currentTown) {
        this.isValidTown =
          this.town === currentTown.Description && this.town.length !== 0 && this.building !== "" && this.flat !== "";
      } else {
        this.isValidTown = false;
      }

      const currentDepartment = this.departments.find(e => e.Description === this.department);
      if (currentDepartment) {
        this.isValidDepartment = this.department === currentDepartment.Description && this.department.length !== 0;
      } else {
        this.isValidDepartment = false;
      }

      this.isValid = this.isValidTown && this.isValidDepartment && this.department && this.building && this.flat;
      this.changeAddress({
        town: this.town,
        department: this.department,
        building: this.building,
        flat: this.flat,
      });
      this.$emit("input", this.isValid);
    },
  },
};

import { mapActions } from "vuex";
import router from "../../../../router";
import mainSelect from "@/components/main-select/index.vue";
import typeSelect from "@/components/group-product/components/select";

export default {
  name: "sort-select",
  data() {
    return {
      selectedFilter: {
        id: 4,
        key: "bestsellers",
        name: this.$t("sortTypes.newest"),
      },
      defaultFilter: "bestsellers",
      checkedFilter: "",
      list: [
        {
          id: 0,
          key: "price_desc",
          name: this.$t("sortTypes.priceDesc"),
        },
        {
          id: 1,
          key: "price_asc",
          name: this.$t("sortTypes.priceAsc"),
        },
        {
          id: 2,
          key: "newest",
          name: this.$t("sortTypes.withPromotions"),
        },
        {
          id: 3,
          key: "with_promotions",
          name: this.$t("sortTypes.bestsellers"),
        },
        {
          id: 4,
          key: "bestsellers",
          name: this.$t("sortTypes.newest"),
        },
      ],
      currentSort: {
        id: 0,
        key: "",
        name: this.$t("default"),
      },
    };
  },
  components: {
    typeSelect,
    mainSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (this.$route.query.sort_filter_type) {
      this.selectedFilter = this.list.find(item => {
        return item.key === this.$route.query.sort_filter_type;
      });
    }
  },
  methods: {
    router() {
      return router;
    },
    changeProductPlacementGroup() {
      this.$router
        .push({
          name: "products",
          query: Object.assign({}, this.$route.query, { catalog_type: "groups" }),
        })
        .catch(() => {});
    },
    changeProductPlacementTable() {
      const query = Object.assign({}, this.$route.query);
      delete query.catalog_type;
      this.$router
        .push({
          name: "products",
          query: query,
        })
        .catch(() => {});
    },
    changeSortMobile() {
      this.$emit("change-event");
    },
    isCurrentSortFilter(filter) {
      return this.checkedFilter === filter.id || this.$route.query?.sort_filter_type === filter.key;
    },
    changeSort(sort) {
      this.currentSort = sort;
      this.$router
        .push({
          name: "products",
          query: Object.assign({}, this.$route.query, { sort_filter_type: this.currentSort.key }),
        })
        .catch(() => {});
    },
  },
  computed: {
    catalogType() {
      if (this.$route.query.catalog_type === "groups") {
        return "activeGroup";
      }

      return "activeTable";
    },
  },
};

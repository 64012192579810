import Vue from "vue";
import { mapKeys, snakeCase } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import StarRating from "vue-star-rating";

export default {
  name: "review-modal",
  components: {
    StarRating,
  },

  data() {
    return {
      loader: true,
      dataProducts: {},
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      reviewProductsData: "popups/reviewModalPayload",
    }),
  },
  created() {
    this.generateFormFields();
  },
  methods: {
    ...mapMutations({
      changeReviewPopup: "popups/CHANGE_REVIEW_MODAL",
    }),

    ...mapActions({
      sendFeedback: "card/SEND_FEEDBACK",
    }),

    generateFormFields() {
      if (!this.reviewProductsData?.products?.data) return;

      this.reviewProductsData.products.data.forEach(item => {
        this.dataProducts[item.id] = {
          ...item,
          loadBtn: false,
          email: this.reviewProductsData.email,
          name: this.reviewProductsData.name,
          productId: item.id,
          rating: 5,
          message: "",
        };
      });

      this.loader = false;
    },

    parsFormData(payload) {
      let formData = new FormData();
      for (let item in payload) {
        if (item === "files") {
          for (let row in payload[item]) {
            formData.append(`files[${row}]`, payload[item][row]);
          }
        } else {
          formData.append(item, payload[item]);
        }
      }
      return formData;
    },

    transformData(item) {
      return mapKeys(this.dataProducts[item], (_value, key) => snakeCase(key));
    },

    submit(id) {
      this.dataProducts[id].loadBtn = true;
      try {
        const formData = this.parsFormData(this.transformData(id));
        this.sendFeedback(formData).then(() => {
          this.$toasted.success(this.$t("successFeedbackSend"));
          Vue.delete(this.dataProducts, id);

          if (!Object.keys(this.dataProducts).length) {
            this.changeReviewPopup({ status: false });
          }
        });
      } catch (error) {
        this.$toasted.error(this.$t("validationRequired"));
      } finally {
        this.dataProducts[id].loadBtn = false;
      }
    },
  },
};

import { mapActions, mapMutations } from "vuex";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "category-select",
  props: {
    childFilter: {
      type: Object,
      default: () => {},
    },
    parentFilter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
      selectedCategory: {},
      checkedFilters: [],
    };
  },
  watch: {
    "$route.query": function () {
      this.getCheckedItems();
    },
  },
  created() {
    this.getCheckedItems();

    if (this.$route.query.slug) {
      this.selectedCategory.slug = this.$route.query.slug;
      this.$emit("show", true);
    }
  },
  methods: {
    getCheckedItems() {
      this.checkedFilters = [];
      for (let key in this.$route.query) {
        const queryElement = this.$route.query[key];
        if (!queryElement) {
          continue;
        }
        if (queryElement.includes(",")) {
          queryElement.split(",").forEach(elem => {
            this.checkedFilters.push(elem);
          });
        } else {
          this.checkedFilters.push(queryElement);
        }
      }
    },
    selectParent() {
      this.$emit("select-parent");
    },
    categorySelect() {
      let urlQuery = cloneDeep(this.$route.query);
      let key = this.childFilter.key;
      let val = this.childFilter.slug;

      if (urlQuery[key]) {
        let arrValues = urlQuery[key].toString().split(",");

        let indexVal = arrValues.findIndex(item => {
          return item === val;
        });
        if (indexVal > -1) {
          arrValues.splice(indexVal, 1);
          if (arrValues.length) {
            urlQuery[key] = arrValues.join(",");
          } else {
            delete urlQuery[key];
          }
        } else {
          urlQuery[key] = urlQuery[key] + "," + val;
        }
      } else {
        urlQuery[key] = val;
      }

      this.$router
        .replace({
          name: "products",
          query: urlQuery,
          params: this.$route.params,
        })
        .catch(() => {});
    },
  },
};

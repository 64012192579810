import { mapActions, mapGetters, mapMutations } from "vuex";
import selectProduct from "@/components/group-product/components/product/index.vue";

export default {
  props: {
    group: {
      type: Object,
      default: () => {},
    },
  },
  components: { selectProduct },
  name: "group-product",
  data() {
    return {
      openSelect: false,
    };
  },
};

<template>
  <h1 class="training-center__banner">
    <span v-html="$t('selectionsBannerLeft')"></span>
    <span v-html="$t('selectionsBannerRight')"></span>
  </h1>
</template>

<style lang="scss">
.training-center__banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 44px;
  padding: 40px 0;
  background: linear-gradient(270deg, #e4cfbf 0%, #f8d9c0 61.5%, #b9c0c0 100%);
  margin: 0;

  span {
    height: 34px;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
    color: #000000;
  }
}
@media (max-width: 1360px) {
  .training-center__banner {
    flex-wrap: wrap;
  }
}

@media (max-width: 838px) {
  .training-center__banner {
    gap: 7px;
    padding: 20px 0;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      height: fit-content;
      text-transform: uppercase;
      color: #000000;
    }
  }
}
</style>

<script>
export default {
  name: "training-banner",
};
</script>

import { mapActions, mapGetters } from "vuex";

export default {
  name: "nav-bar",
  computed: {
    ...mapGetters({
      user: "profile/user",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      isManager: "profile/isManager",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/LOGOUT",
    }),
  },
};

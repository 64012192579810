import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";

interface State {
	promotionsProducts: any,
}

const state: State = {
	promotionsProducts: null,
};

const getters = {
	promotionsProducts: (state: State) => state.promotionsProducts,
};

const actions = {
	FETCH_PROMOTIONS_PRODUCTS: async ({ commit }: ActionContext<State, RootState>, params: any) => {
		try {
			const response = await $http.get("v1/sales", { params });
			commit("SET_PROMOTIONS_PRODUCTS", response.data);
			return response.data;
		} catch (e) {
			throw e;
		}
	},
	FETCH_PROMOTIONS_PRODUCTS_FOR_BASKET: async ({ commit }: ActionContext<State, RootState>, productIds: Array<number>) => {
		try {
			const response = await $http.get("v1/basket/products", { params: { ids: productIds } });
			return response.data;
		} catch (e) {
			throw e;
		}
	},
};

const mutations = {
	SET_PROMOTIONS_PRODUCTS(state: State, products: any) {
		state.promotionsProducts = products;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

import { mapActions } from "vuex";
import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
  name: "Manager-item",
  props: {
    manager: Object,
  },

  data() {
    return {
      edit: false,
      activationLoading: false,
      editLoading: false,
      managerInfo: this.manager,
      payload: {
        id: this.manager.id,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      validationErrors: {},
    };
  },

  mixins: [validation],
  validations: {
    payload: {
      first_name: {
        required,
        maxLength: maxLength(255),
      },
      last_name: {
        required,
        maxLength: maxLength(255),
      },
      email: {
        required,
        maxLength: maxLength(120),
        email,
      },
      password: {
        minLength: minLength(8),
        maxLength: maxLength(120),
      },
    },
  },

  created() {
    this.payload.first_name = this.manager.firstName;
    this.payload.last_name = this.manager.lastName;
    this.payload.email = this.manager.email;
  },

  computed: {
    firstNameErrors() {
      return this.computeErrors("payload", "first_name", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 255 },
      });
    },
    lastNameErrors() {
      return this.computeErrors("payload", "last_name", {
        required: "validationRequired",
        maxLength: { message: "validationMax", count: 255 },
      });
    },
    passwordErrors() {
      return this.computeErrors("payload", "password", {
        minLength: { message: "validationMin", count: 8 },
        maxLength: { message: "validationMax", count: 120 },
      });
    },
    emailErrors() {
      return this.computeErrors("payload", "email", {
        required: "validationRequired",
        email: "validationEmail",
        maxLength: { message: "validationMax", count: 120 },
      });
    },
  },

  methods: {
    ...mapActions({
      changeManagerActivations: "managers/CHANGE_MANAGER_ACTIVATIONS",
      deleteManager: "managers/DELETE_MANAGER",
      updateManager: "managers/UPDATE_MANAGER",
    }),

    handleEditButton() {
      if (this.edit) {
        this.updateMangerInfo();
      } else {
        this.edit = true;
      }
    },

    changeManagerStatus() {
      this.activationLoading = true;
      this.changeManagerActivations({
        id: this.manager.id,
        status: !this.managerInfo.isActive ? "activate" : "deactivate",
      })
        .then(response => {
          this.managerInfo.isActive = response.data.data.isActive;
          this.$toasted.success(response.data.message);
        })
        .finally(() => {
          this.activationLoading = false;
        });
    },

    deleteManagerUser() {
      this.deleteManager(this.managerInfo.id).then(response => {
        this.$toasted.success(response.message);
      });
    },

    updateMangerInfo() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.editLoading = true;
      let payload = { ...this.payload };
      if (!payload.password) delete payload.password;
      this.updateManager(payload)
        .then(response => {
          this.$toasted.success(response.message);
          this.managerInfo = response.data;
          this.edit = false;
        })
        .finally(() => {
          this.editLoading = false;
        });
    },
  },
};

import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { downloadFileFrom } from "@/helpers";

export default {
  name: "buy-flow",
  computed: {
    ...mapGetters({
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      productsPdf: "order/productsPdf",
      basket: "basket/basket",
      downloadPdfLoading: "order/downloadPdfLoading",
    }),
  },
  methods: {
    ...mapActions({
      fetchPdf: `order/DOWNLOAD_PDF`,
      getClientProducts: "basket/GET_CLIENT_PRICE_BASKET",
    }),
    ...mapMutations({
      changeDefaultPricesPopup: `popups/CHANGE_DEFAULT_PRICES`,
    }),
    async showClientPrices() {
      await this.getClientProducts({ ids: _.map(this.basket, "id") });

      this.changeDefaultPricesPopup(true);
    },
    async downloadPdf() {
      const products = _.map(this.basket, product => ({
        id: product.id,
        value: product.select_type.value,
        count: product.select_count,
      }));

      await this.fetchPdf({ products });
      await downloadFileFrom(this.productsPdf.path, "Накладна для пацієнта.pdf");
    },
  },
};

import { mapActions, mapGetters } from "vuex";
import pdf from "vue-pdf";

export default {
  name: "user-comment",
  components: {
    pdf,
  },
  data() {
    return {
      edit: false,
      payload: {
        files: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "profile/user",
      isProfessional: "profile/isProfessional",
    }),
  },
  methods: {
    ...mapActions({
      updateDocuments: "profile/PROFESSIONAL_ADD_FILES",
      fetchUser: "profile/FETCH_USER_DATA",
    }),
    handleFileChange(evt) {
      let arr = Array.from(evt.target.files);
      this.payload.files = this.payload.files.concat(arr);
      this.updateDocuments(this.parsFormData(this.payload)).then(() => {
        this.fetchUser();
        this.$toasted.success(this.$t("successAddFiles"));
        this.payload.files = [];
      });
    },
    openDocument(link) {
      window.open(link, "_blank");
    },
    parsFormData(obj) {
      let fd = new FormData();
      for (let i in obj) {
        if (i === "files") {
          for (let j in obj[i]) {
            fd.append(`files[${j}]`, obj[i][j]);
          }
        } else {
          fd.append(i, obj[i]);
        }
      }
      return fd;
    },
  },
};

import { mapActions, mapGetters } from "vuex";
import salesInvoice from "@/modules/profile/components/sales-Invoice/index.vue";
import expandedInvoice from "@/modules/profile/components/expanded-invoice/index.vue";

export default {
  name: "distributor-orders-moving",
  data() {
    return {
      showMovingItems: true,
      openDocument: null,
    };
  },
  components: {
    salesInvoice,
    expandedInvoice,
  },
  watch: {
    "$route.query.open": function (newVal) {
      if (newVal) {
        this.showMovingItems = false;
        const correspondingDocument = this.documents.find(doc => doc.id === +newVal);
        if (correspondingDocument) {
          this.openDocument = correspondingDocument;
        }
      } else {
        this.showMovingItems = true;
        this.openDocument = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      documents: "documents/documents",
      haveNextPage: "documents/haveNextPage",
      loading: "documents/loading",
      documentInfo: "documents/documentInfo",
      isManager: "profile/isManager",
    }),
  },
  async created() {
    await this.fetchDocuments({ type: "transfer" });
  },
  methods: {
    ...mapActions({
      fetchDocuments: "documents/GET_DOCUMENTS",
      fetchDocumentsNextPage: "documents/GET_DOCUMENTS_NEXT_PAGE",
    }),
    hideMovingItems(document) {
      this.showMovingItems = false;
      this.openDocument = document;
      this.$router.push({ query: { open: document.id } });
    },
    async loadMoreDocuments() {
      await this.fetchDocumentsNextPage({ type: "transfer" });
    },
  },
};

import { mapGetters, mapMutations } from "vuex";
import Container from "./container/index.vue";

export default {
  name: "admin-bar",
  components: {
    Container,
  },
  computed: {
    ...mapGetters({
      user: "profile/user",
      adminData: "profile/adminData",
    }),
    canBeRendered() {
      return this.user && this.user.canSeeAdminHeader;
    },
  },
  watch: {
    adminData() {
      this.adminHomeLink = this.adminData.adminHome;
      this.links = this.adminData.links;
    },
    ["$route"]() {
      this.setAdminData({
        adminHome: this.adminHomeLink,
        resetLinks: true,
      });
    },
  },
  data() {
    return {
      adminHomeLink: null,
      links: [],
    };
  },
  methods: {
    ...mapMutations({
      setAdminData: "profile/setAdminData",
    }),
  },
};

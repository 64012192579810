import { mapGetters } from "vuex";

export default {
  name: "seminar-details",
  computed: {
    ...mapGetters({
      seminarDetails: "popups/seminarDetails",
    }),
  },
  created() {
    document.getElementById("html").classList.add("hide");
  },
};

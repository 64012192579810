import _ from "lodash";
import { UserTypes } from "@/types/user";

/*
const UserType = {
  Distributor: 'distributor',
  Professional: 'professional',
  Authorized_user: 'authorized_user',
  Unauthorized_user: 'unauthorized_user',
}
*/

/**
 * @param {User} user
 * @param {Array<UserType|UserTypes>|UserType|UserTypes} userTypes
 * @return {boolean}
 */
const checkUserType = (user, userTypes) => {
  if (!user) return false;

  if (!_.isArray(userTypes)) userTypes = [userTypes];
  return userTypes.includes(user.userType.name);
};

/**
 * @param {User} user
 * @return {boolean}
 */
const isManager = user => {
  return checkUserType(user, UserTypes.Distributor) && user.isManager;
};

export default {
  checkUserType,
  isManager,
};

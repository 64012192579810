var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"promotions__catalog-item"},[(_vm.promotionsProduct.buttonType === 'link')?[_c('div',[_c('RouterLink',{staticClass:"promotions__catalog-img",attrs:{"to":{
          name: 'products',
          query: { brand: _vm.promotionsProduct.brand.name, sort_filter_type: 'with_promotions' },
        }}},[_c('img',{attrs:{"src":_vm.promotionsProduct.image,"alt":_vm.promotionsProduct.brand.name}})]),_c('div',{staticClass:"promotions__catalog-buy"},[_c('a',{attrs:{"href":_vm.promotionsProduct.link}},[_vm._v("Придбати")])])],1)]:(_vm.promotionsProduct.buttonType === 'basket')?[_c('div',{staticClass:"promotions__catalog-img"},[_c('RouterLink',{staticClass:"promotions__catalog-img",attrs:{"to":{
          name: 'products',
          query: { brand: _vm.promotionsProduct.brand.name, sort_filter_type: 'with_promotions' },
        }}},[_c('img',{attrs:{"src":_vm.promotionsProduct.image,"alt":_vm.promotionsProduct.brand.name}})]),_c('div',{staticClass:"promotions__catalog-buy"},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.addAllProductsToBasket}},[_vm._v("Придбати")])])],1)]:[_c('div',[_c('RouterLink',{staticClass:"promotions__catalog-img",attrs:{"to":{
          name: 'products',
          query: { brand: _vm.promotionsProduct.brand.name, sort_filter_type: 'with_promotions' },
        }}},[_c('img',{attrs:{"src":_vm.promotionsProduct.image,"alt":_vm.promotionsProduct.brand.name}}),_c('div',{staticClass:"promotions__catalog-buy"},[_c('button',{attrs:{"type":"button"}},[_vm._v("Придбати")])])])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "user-info",
  data() {
    return {
      payload: {
        assignTo: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      cosmetologistId: "pages/page",
      isProfessional: "profile/isProfessional",
      user: "profile/user",
    }),
  },
  created() {
    this.fetchCosmetologistPage("cosmetologist-id");
  },
  methods: {
    ...mapActions({
      fetchCosmetologistPage: "pages/GET_PAGE",
      updateUserAssignCode: "profile/UPDATE_USER_DATA",
      fetchUser: "profile/FETCH_USER_DATA",
    }),
    ...mapMutations({
      changeAnotherCosmetologPopup: `popups/CHANGE_COSMETOLOG_POPUP`,
    }),
    async submit() {
      try {
        await this.updateUserAssignCode({ assign_to: this.payload.assignTo });
        if (this.isProfessional) {
          this.$toasted.success(this.$t("successAddDistributor"));
        } else {
          this.$toasted.success(this.$t("successAddCosmetolog"));
        }
        await this.fetchUser();
      } catch (error) {
        const message = error.data?.message;
        if (message) {
          this.$toasted.error(message);
        } else {
          this.$toasted.error("Щось пішло не так, спробуйте пізніше");
        }
      }
    },
  },
};

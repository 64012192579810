import { mapActions, mapGetters } from "vuex";
import { email, minLength, required } from "vuelidate/lib/validators";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import validation from "@/mixins/validation";

export default {
  name: "user-data",
  data() {
    return {
      payload: {
        phone_country: "",
        phone: "",
        email: "",
      },
      isEdit: false,
      validationErrors: {},
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      phone: {
        required,
        minLength: minLength(12),
      },
      email: {
        email,
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "profile/user",
      updateUserLoading: "profile/updateUserLoading",
    }),
    emailErrors() {
      return this.computeErrors("payload", "email", {
        required: "validationRequired",
        email: "validationEmail",
      });
    },
    phoneErrors() {
      return this.computeErrors("payload", "phone", {
        required: "validationRequired",
        minLength: { message: "validationMin", count: 13 },
      });
    },
  },
  watch: {
    user(newVal) {
      if (newVal) {
        if (this.user) this.setUserInfo();
      }
    },
  },
  created() {
    if (this.user) this.setUserInfo();
  },
  methods: {
    ...mapActions({
      updateUserData: "profile/UPDATE_USER_DATA",
    }),
    setUserInfo() {
      this.payload.phone = this.user.phone;
      this.payload.email = this.user.email;
    },
    resetForm() {
      this.isEdit = false;
      this.$v.$reset();
      this.setUserInfo();
    },
    update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const parsedNumber = parsePhoneNumberFromString(this.payload.phone);

        if (parsedNumber) {
          this.payload.phone_country = parsedNumber.country;
        } else {
          this.payload.phone_country = "";
        }

        this.updateUserData(this.payload)
          .then(() => {
            this.$toasted.success(this.$t("successResetContact"));
            this.isEdit = false;
          })
          .catch(error => {
            this.$toasted.error(error.data.message);
          });
      }
    },
  },
};

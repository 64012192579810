import { mapActions, mapGetters, mapMutations } from "vuex";
import salesInvoice from "@/modules/profile/components/sales-Invoice/index.vue";
import expandedInvoice from "@/modules/profile/components/expanded-invoice/index.vue";
import storageInvoice from "@/modules/profile/components/storage-invoice/index.vue";
import { downloadFileFrom } from "@/helpers";
import { makeid } from "@/helpers";

export default {
  name: "distributor-storage-report",
  data() {
    return {
      brandSelected: [],
      seriesSelected: [],
      countChange: [],
      isOpenBrand: false,
      calculated: false,
      edit: false,
      comment: "",
      promocode: "",
      sendFormProgress: false,
      orderId: null,
      fileDownloadState: false,
      do_not_callback: false,
      goes_to_patient: false,
      showBonusesInput: false,
      bonusesAmount: "Введіть кількість бонусів",
      hash: "",
    };
  },
  components: { storageInvoice, expandedInvoice, salesInvoice },
  async created() {
    await this.fetchRemainings();
    this.hash = this.makeid(20);
  },
  methods: {
    makeid,
    ...mapActions({
      fetchRemainings: "remainings/GET_REMAININGS",
      submitData: "remainings/SEND_REMAININGS",
      fetchFile: "documents/GET_DOCUMENT_EXCEL",
      checkOrder: "order/CHECK_ORDER",
      fetchPromocodeInfo: "basket/FETCH_PROMOCODE_INFO",
    }),

    ...mapMutations({
      showRemainigsPopup: "popups/CHANGE_REMAININGS_POPUP",
    }),

    getItemCount(id) {
      const item = this.countChange.find(item => item.product_price_id === id);
      return item ? item.count : 0;
    },

    getTotalChangedSum() {
      return this.countChange.reduce((sum, item) => sum + item.price * item.count, 0);
    },

    inputBonusAmount(event) {
      let inputValue = event.target.value ? parseInt(event.target.value) : 0;
      let inputField = event.target;
      if (isNaN(inputValue)) {
        inputValue = 0;
      } else if (inputValue > this.user.bonusAmount) {
        inputValue = this.user.bonusAmount;
      }
      this.bonusesAmount = inputValue;
      inputField.value = this.bonusesAmount;
    },

    async downloadDocument() {
      this.fileDownloadState = true;
      const response = await this.fetchFile({ id: this.orderId });
      await downloadFileFrom(response.data.path, response.data.name + ".xls");
      this.fileDownloadState = false;
    },

    async sendForm() {
      try {
        this.sendFormProgress = true;
        const requestPayload = {
          email: this.user.email,
          first_name: this.user.name,
          last_name: this.user.secondName,
          phone: this.user.phone,
          products: this.countChange,
          hash: this.hash,
          promocode: this.promocode,
        };
        if (this.showBonusesInput && Number.isInteger(this.bonusesAmount) && this.bonusesAmount > 0) {
          requestPayload["pay_bonus_amount"] = this.bonusesAmount;
        }
        await this.checkOrder(requestPayload);
        if (!this.orderCheckResult.result) {
          this.showRemainigsPopup(true);
          this.sendFormProgress = false;
          return;
        }
        const requsetData = {
          comment: this.comment,
          products: this.countChange,
          hash: this.hash,
          promocode: this.promocode,
        };
        if (this.showBonusesInput && Number.isInteger(this.bonusesAmount) && this.bonusesAmount > 0) {
          requsetData["pay_bonus_amount"] = this.bonusesAmount;
        }
        const response = await this.submitData(requsetData);
        this.orderId = response.data.data.data.order.id;
        this.$toasted.success(response.data.data.message);
        this.sendFormProgress = false;
      } catch (e) {
        throw e;
      }
    },

    incrementCount(id, quantity, price, product) {
      if (this.countChange.find(item => item.product_price_id === id)) {
        const item = this.countChange.find(item => item.product_price_id === id);
        if (item.count >= quantity) return;
        item.count += 1;
      } else {
        this.countChange.push({
          product_price_id: id,
          count: 1,
          quantity: quantity,
          price: price,
          product: product,
        });
      }
    },

    decrementCount(id) {
      const item = this.countChange.find(item => item.product_price_id === id);
      if (item && item.count > 0) {
        item.count -= 1;
      }
      if (item.count === 0) {
        this.removeItem(id);
      }
    },

    handleCountChange(event, id, quantity, price, product) {
      let inputCount = parseInt(event.target.value);
      if (isNaN(inputCount)) inputCount = 1;
      if (inputCount > quantity) inputCount = quantity;
      if (this.countChange.find(item => item.product_price_id === id)) {
        const item = this.countChange.find(item => item.product_price_id === id);
        if (inputCount > quantity) {
          item.count = quantity;
          return;
        }
        if (inputCount <= 0) {
          item.count = 1;
          return;
        }
        item.count = inputCount;
      } else {
        this.countChange.push({
          product_price_id: id,
          count: inputCount,
          quantity: quantity,
          price: price,
          product: product,
        });
      }

      this.$forceUpdate();
    },

    removeItem(id) {
      const index = this.countChange.findIndex(item => item.product_price_id === id);
      if (index !== -1) {
        this.countChange.splice(index, 1);
      }
    },

    handleCalculation() {
      this.calculated = true;
    },

    handleProductChange(payload) {
      this.countChange = payload;
    },
  },
  computed: {
    ...mapGetters({
      list: "remainings/list",
      loading: "remainings/loading",
      orderCheckResult: "order/orderCheckResult",
      user: "profile/user",
      canUseBonuses: "profile/canUseBonuses",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      isManager: "profile/isManager",
    }),
  },
};

import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type {
	Course,
	CourseTheme,
	FinishQuizPayload,
	GetQuizPayload,
	Quiz, QuizResult,
	QuizToken,
	StartQuizPayload,
} from "@/types/courses";

type QuizResultInfoResponse = { message: string; data: QuizResult };

interface State {
	quizToken: string;
	quizInfo: Quiz | null;
	quizResultInfo: QuizResultInfoResponse | null;
}

const state: State = {
	quizToken: "",
	quizInfo: null,
	quizResultInfo: null,
};

const getters = {
	quizToken: (state: State) => state.quizToken,
	quizInfo: (state: State) => state.quizInfo,
	quizResultInfo: (state: State) => state.quizResultInfo,
};

const actions = {
	GET_COURSE_THEMES: async () => {
		try {
			const response = await $http.get<{ data: Array<CourseTheme> }>(`v1/platform/course-themes`);

			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	GET_COURSE_THEME: async ({ commit }: ActionContext<State, RootState>, courseThemeId: number) => {
		try {
			const response = await $http.get<{ data: CourseTheme }>(`v1/platform/course-themes/${courseThemeId}`);

			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	GET_COURSES_LIST: async ({ commit }: ActionContext<State, RootState>, courseThemeId: number) => {
		try {
			const response = await $http.get<{ data: Array<Course> }>(`v1/platform/course-themes/${courseThemeId}/courses`);

			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	START_QUIZ: async ({ commit }: ActionContext<State, RootState>, payload: StartQuizPayload) => {
		try {
			const result = await $http.post<QuizToken>(`v1/platform/course-themes/${payload.courseThemeId}/courses/${payload.courseId}/quiz/start`);
			commit("SET_QUIZ_TOKEN", result.data.quizToken);
		} catch (e) {
			throw e;
		}
	},
	GET_QUIZ_QUESTIONS: async ({ commit }: ActionContext<State, RootState>, payload: GetQuizPayload) => {
		try {
			const response = await $http.get<{
				data: Quiz
			}>(`v1/platform/course-themes/${payload.courseThemeId}/courses/${payload.courseId}/quiz/${payload.quizToken}`);

			commit("SET_QUESTIONS", response.data.data);
		} catch (e) {
			throw e;
		}
	},
	SEND_QUIZ_ANSWERS: async ({ commit }: ActionContext<State, RootState>, payload: FinishQuizPayload) => {
		try {
			const response = await $http.put<QuizResultInfoResponse>(`v1/platform/course-themes/${payload.courseThemeId}/courses/${payload.courseId}/quiz/${payload.quiz_token}`, payload);

			commit("SET_ANSWERS", response.data);
		} catch (e) {
			throw e;
		}
	},
};

const mutations = {
	SET_QUIZ_TOKEN(state: State, token: string) {
		state.quizToken = token;
	},
	SET_QUESTIONS(state: State, quiz: Quiz) {
		state.quizInfo = quiz;
	},
	SET_ANSWERS(state: State, data: QuizResultInfoResponse) {
		state.quizResultInfo = data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

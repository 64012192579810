import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "delivery-nova-poshta",
  data() {
    return {
      town: "",
      department: "",
      isValid: false,
      isValidTown: true,
      isValidDepartment: true,
    };
  },
  props: {
    townError: {
      type: Boolean,
      default: true,
    },
    departmentError: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      towns: "order/towns",
      departments: "order/departments",
      isTownsLoading: "order/isTownsLoading",
      isDepartmentsLoading: "order/isDepartmentsLoading",
      deliveryAddress: "order/deliveryAddress",
    }),
  },
  watch: {
    town: function () {
      const currentTown = this.towns.find(e => e.Description === this.town);
      let currentDepartment = this.departments.find(e => e.Description === this.department);
      let cityError = this.$refs.cityError;
      let departmentError = this.$refs.departmentError;
      currentDepartment
        ? (departmentError.innerHTML = "")
        : (departmentError.innerHTML = this.$t("validationRequired"));

      currentTown ? (cityError.innerHTML = "") : (cityError.innerHTML = this.$t("validationRequired"));
      if (this.departments) {
        currentDepartment = this.departments.find(e => e.Description === this.department);
      }

      this.department = "";
      this.saveAddress({
        city: currentTown,
        department: currentDepartment,
      });
      if (!currentTown) {
        return;
      }

      if (this.town === currentTown.Description) {
        this.onRequestDepartments("");
      } else {
        this.setDepartments([]);
      }
      this.changeAddress({ town: this.town, department: this.department });
      this.checkValidData();
    },
    department() {
      const currentTown = this.towns.find(e => e.Description === this.town);
      const currentDepartment = this.departments.find(e => e.Description === this.department);
      let departmentError = this.$refs.departmentError;
      currentDepartment
        ? (departmentError.innerHTML = "")
        : (departmentError.innerHTML = this.$t("validationRequired"));
      this.saveAddress({
        city: currentTown,
        department: currentDepartment,
      });
    },
  },
  created() {
    this.onRequestTown();
  },
  methods: {
    ...mapActions({
      fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
      fetchDepartments: "order/NOVA_POSHTA_GET_DEPARTMENS",
    }),
    ...mapMutations({
      setDepartments: "order/NOVA_POSHTA_SET_DEPARTMENS",
      changeAddress: "order/CHANGE_ADDRESS",
      saveAddress: "order/SAVE_DELIVERY_ADDRESS",
    }),
    onRequestTown(val) {
      const formData = JSON.stringify({
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: val,
          Language: "ua",
        },
        apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
      });
      this.fetchTowns(formData).then(() => {
        const currentTown = this.towns.find(e => e.Description === this.town);
        this.department = "";

        if (!currentTown) {
          return;
        }

        if (this.town === currentTown.Description) {
          this.onRequestDepartments("");
        } else {
          this.setDepartments([]);
        }
        this.checkValidData();
      });
    },
    onRequestDepartments(val) {
      const formData = JSON.stringify({
        modelName: "AddressGeneral",
        calledMethod: "getWarehouses",
        methodProperties: {
          CityName: this.town,
          FindByString: val,
          Language: "ua",
        },
        apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
      });
      this.fetchDepartments(formData).then(() => {
        this.checkValidData();
      });
    },
    checkValidData() {
      const currentTown = this.towns.find(e => e.Description === this.town);
      if (currentTown) {
        if (this.town) {
          this.isValidTown = this.town === currentTown.Description && this.town.length !== 0;
        }
      }
      if (this.town && this.town !== currentTown?.Description && this.town.length !== 0) {
        this.isValidTown = false;
      }

      const currentDepartment = this.departments.find(e => e.Description === this.department);
      if (currentDepartment) {
        this.isValidDepartment = this.department === currentDepartment.Description && this.department.length !== 0;
      } else {
        this.isValidDepartment = false;
      }

      this.isValid = this.isValidTown && this.isValidDepartment;
      if (this.isValid) {
        this.changeAddress({ town: this.town, department: this.department });
      }

      this.$emit("input", this.isValid);
    },
  },
};

import store from "@/store";

export function globalLoader(state) {
  store.commit(`system/SHOW_GLOBAL_LOADER`, state);
}

export function fixBody(state) {
  store.commit(`system/FIX_BODY`, state);
}

export const isMobile = {
  Android: () => navigator.userAgent.includes("Android"),
  BlackBerry: () => navigator.userAgent.includes("BlackBerry"),
  iOS: () =>
    navigator.userAgent.includes("iPhone") ||
    navigator.userAgent.includes("iPad") ||
    navigator.userAgent.includes("iPod"),
  Windows: () => navigator.userAgent.includes("IEMobile"),
  any: () => isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows(),
};

export const isIPad = {
  iOS: () => /iPad|iPod/i.test(navigator.userAgent),
};

import { mapActions, mapMutations } from "vuex";

export default {
  name: "promotions-product",
  props: {
    promotionsProduct: {
      type: [Object, Array],
      required: true,
    },
  },
  methods: {
    ...mapMutations({
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
      changeBasketPopup: "basket/SHOW_BASKET_POPUP",
    }),
    ...mapActions({
      fetchPromotionsProductsForBasket: "promotions/FETCH_PROMOTIONS_PRODUCTS_FOR_BASKET",
    }),
    async addAllProductsToBasket() {
      const ids = this.promotionsProduct.basketIds.map(item => item);
      const response = await this.fetchPromotionsProductsForBasket(ids);
      response.data.map(item => {
        this.addToBasket({
          select_count: 1,
          product_price: item.productPrices.data[0],
          product: item,
        });
      });
      this.changeBasketPopup(true);
    },
  },
};

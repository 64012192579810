import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { BrandGroup } from "@/types/remainings";
import type { TCreateRemainingReportPayload, TRemainingReportResponse } from "@/store/modules/types/remainings";

interface State {
	list: Array<BrandGroup>;
	loading: boolean;
}

const state: State = {
	list: [],
	loading: false,
};

const getters = {
	list: (state: State) => state.list,
	loading: (state: State) => state.loading,
};

const actions = {
	GET_REMAININGS: async ({ commit, state }: ActionContext<State, RootState>) => {
		state.loading = true;
		try {
			commit("SET_REMAININGS", null);
			const response = await $http.get<{ data: Array<BrandGroup> }>("v1/remainings");
			commit("SET_REMAININGS", response.data.data);
		} catch (e) {
			throw e;
		} finally {
			state.loading = false;
		}
	},
	SEND_REMAININGS: async (state: ActionContext<State, RootState>, payload: TCreateRemainingReportPayload) => {
		try {
			const response = await $http.post<TRemainingReportResponse>("v1/remainings/send-report", payload);
			return response;
		} catch (e) {
			throw e;
		}
	},
	GET_REMAINING_FILE: async (state: ActionContext<State, RootState>) => {
		try {
			return await $http.get("v1/remainings/attachment");
		} catch (e) {
			throw e;
		}
	},
};

const mutations = {
	SET_REMAININGS(state: State, list: Array<BrandGroup>) {
		state.list = list;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

import axios from "axios";
import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { DeliveryMethod, Invoice, Order, UserAddress1C } from "@/types/order";
import type { Certificate } from "@/types/api";
import type { Product } from "@/types/shop";
import type {
	AddressCourierData,
	CheckResult,
	CreateInvoicePayload,
	CreateTransactionPayload,
	Delivery,
	DownloadClientPricesPayload,
	DownloadPdfInfo,
	InvoiceInfo,
	NovaPoshtaDepartmen,
	NovaPoshtaTown,
	OrderCreatePayload,
	RepeatProductData,
	TransactionInfo,
} from "@/store/modules/types/order";

interface State {
	deliveryAddress: Delivery | Object;
	savedAddress: UserAddress1C | null;
	addresses: Array<UserAddress1C>;
	invoiceResult: Invoice | null;
	isInvoiceLoading: boolean;
	transactionResult: TransactionInfo | null;
	isTransactionLoading: boolean;
	productId: number | string | null;
	orderResult: Order | null;
	orderCheckResult: CheckResult | null;
	deliveryMethods: Array<DeliveryMethod>;
	repeatProducts: Array<RepeatProductData>;
	productsPdf: DownloadPdfInfo | null;
	certificate: Certificate | null;
	isLoading: boolean;
	isAddressLoading: boolean;
	address: any;
	addressCourier: AddressCourierData;
	userInfo: any;
	isDepartmentsLoading: boolean;
	isTownsLoading: boolean;
	towns: Array<NovaPoshtaTown>;
	departments: Array<NovaPoshtaDepartmen>;
	downloadPdfLoading: boolean;

	certificateLoading: boolean;
	courierProduct: Product | null;
	isGiftLoading: boolean;
	giftProduct: Product | null;
}

const state: State = {
	savedAddress: null,
	invoiceResult: null,
	isInvoiceLoading: false,
	transactionResult: null,
	isTransactionLoading: false,
	orderResult: null,
	orderCheckResult: null,
	productId: null,
	deliveryMethods: [],
	repeatProducts: [],
	productsPdf: null,
	downloadPdfLoading: false,
	certificate: null,
	addresses: [],
	isLoading: false,
	userInfo: {},
	addressCourier: {},
	address: {
		town: "",
	},
	isDepartmentsLoading: false,
	isTownsLoading: false,
	towns: [],
	departments: [],

	certificateLoading: false,
	deliveryAddress: Object,
	isAddressLoading: false,
	courierProduct: null,
	isGiftLoading: false,
	giftProduct: null,
};

const getters = {
	isAddressLoading: (state: State) => state.isAddressLoading,
	deliveryAddress: (state: State) => state.deliveryAddress,
	savedAddress: (state: State) => state.savedAddress,
	addresses: (state: State) => state.addresses,
	invoiceResult: (state: State) => state.invoiceResult,
	isInvoiceLoading: (state: State) => state.isInvoiceLoading,
	transactionResult: (state: State) => state.transactionResult,
	isTransactionLoading: (state: State) => state.isTransactionLoading,
	productId: (state: State) => state.productId,
	orderResult: (state: State) => state.orderResult,
	orderCheckResult: (state: State) => state.orderCheckResult,
	deliveryMethods: (state: State) => state.deliveryMethods,
	courierProduct: (state: State) => state.courierProduct,
	repeatProducts: (state: State) => state.repeatProducts,
	productsPdf: (state: State) => state.productsPdf,
	certificate: (state: State) => state.certificate,
	isLoading: (state: State) => state.isLoading,
	address: (state: State) => state.address,
	addressCourier: (state: State) => state.addressCourier,
	isDepartmentsLoading: (state: State) => state.isDepartmentsLoading,
	isTownsLoading: (state: State) => state.isTownsLoading,
	towns: (state: State) => state.towns,
	departments: (state: State) => state.departments,
	downloadPdfLoading: (state: State) => state.downloadPdfLoading,
	giftProduct: (state: State) => state.giftProduct,
};

const actions = {
	GET_ADDRESS_LIST: async ({ commit }: ActionContext<State, RootState>) => {
		try {
			commit("NOVA_POSHTA_GET_ADDRESS_LOADING", true);
			const response = await $http.get<{ data: Array<UserAddress1C> }>(`v1/user/addresses`);
			commit("SET_ADDRESS_LIST", response.data.data);
			return response.data.data;
		} catch (e) {
			throw e;
		} finally {
			commit("NOVA_POSHTA_GET_ADDRESS_LOADING", false);
		}
	},
	GET_CERTIFICATE: async ({ commit }: ActionContext<State, RootState>, payload: string) => {
		try {
			commit("GET_CERTIFICATE_LOADING", true);
			const response = await $http.get(`v1/certificates/${payload}`);
			commit("SET_CERTIFICATE", response.data.data);
			return response.data.data;
		} catch (e) {
			throw e;
		} finally {
			commit("GET_CERTIFICATE_LOADING", false);
		}
	},
	DOWNLOAD_PDF: async ({ commit }: ActionContext<State, RootState>, payload: DownloadClientPricesPayload) => {
		try {
			commit("CHANGE_DOWNLOAD_PDF_LOADING", true);
			const response = await $http.post<{ data: DownloadPdfInfo }>(`v1/client_prices/download`, payload);
			commit("SET_DOWNLOAD_PDF", response.data.data);
			return response.data.data;
		} catch (e) {
			throw e;
		} finally {
			commit("CHANGE_DOWNLOAD_PDF_LOADING", false);
		}
	},
	REPEAT_ORDER: async ({ commit }: ActionContext<State, RootState>, orderId: number | string) => {
		try {
			const response = await $http.get<{ data: RepeatProductData[] }>(`v1/order/${orderId}/repeat`);
			commit("SET_REPEAT_PRODUCTS", response.data.data);
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	GET_DELIVERY_METHODS: async ({ commit }: ActionContext<State, RootState>) => {
		try {
			const response = await $http.get<{ data: Array<DeliveryMethod> }>("v1/delivery_methods");
			commit("SET_DELIVERY_METHODS", response.data.data);
			const courier = response.data.data.find(item => item.type === "courier");
			if (courier && courier.products && courier.products[0]) {
				commit("SET_COURIER_PRODUCT", courier.products[0]);
			}
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	CREATE_INVOICE: async ({ commit }: ActionContext<State, RootState>, payload: CreateInvoicePayload) => {
		try {
			commit("CHANGE_INVOICE_LOADING", true);
			const response = await $http.post<{ data: InvoiceInfo }>("v1/invoices", payload);
			commit("SET_INVOICE", response.data.data);
			return response.data.data;
		} catch (e) {
			throw e;
		} finally {
			commit("CHANGE_INVOICE_LOADING", false);
		}
	},
	CREATE_TRANSACTION: async ({ commit }: ActionContext<State, RootState>, payload: CreateTransactionPayload) => {
		try {
			commit("CHANGE_TRANSACTION_LOADING", true);
			const response = await $http.post<{ data: TransactionInfo }>("v1/transaction", payload);
			commit("SET_TRANSACTION", response.data);
			return response.data;
		} catch (e) {
			throw e;
		} finally {
			commit("CHANGE_TRANSACTION_LOADING", false);
		}
	},
	CHECK_GIFT: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
		try {
			commit("CHANGE_GIFT_LOADING", true);
			const response = await $http.get<{ data: any }>("v1/products/check-gift", {
				params: payload,
			});
			commit("SET_GIFT_PRODUCT", response.data.data);
			return response.data.data;
		} catch (e) {
			throw e;
		} finally {
			commit("CHANGE_GIFT_LOADING", false);
		}
	},

	CREATE_ORDER: async ({ commit }: ActionContext<State, RootState>, payload: OrderCreatePayload) => {
		commit("CHANGE_LOADING", true);
		try {
			const response = await $http.post<{ data: Order }>(`v1/checkout/store`, payload);
			commit("SET_ORDER", response.data.data);
		} catch (e) {
			throw e;
		} finally {
			commit("CHANGE_LOADING", false);
		}
	},
	CHECK_ORDER: async ({ commit }: ActionContext<State, RootState>, payload: OrderCreatePayload) => {
		commit("CHANGE_LOADING", true);
		try {
			const response = await $http.post<CheckResult>(`v1/checkout/check-quantity`, payload);
			commit("SET_ORDER_CHECK", response.data);
		} catch (e) {
			throw e;
		} finally {
			commit("CHANGE_LOADING", false);
		}
	},
	NOVA_POSHTA_GET_TOWNS: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
		commit("NOVA_POSHTA_GET_TOWNS_LOADING", true);
		try {
			const response = await axios({
				method: "POST",
				url: "https://api.novaposhta.ua/v2.0/json/",
				data: payload,
			});
			commit("NOVA_POSHTA_SET_TOWNS", response.data.data);
			commit("NOVA_POSHTA_SET_DEPARTMENS", []);
		} catch (e) {
			throw e;
		} finally {
			commit("NOVA_POSHTA_GET_TOWNS_LOADING", false);
		}
	},
	NOVA_POSHTA_GET_DEPARTMENS: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
		commit("NOVA_POSHTA_GET_DEPARTMENS_LOADING", true);
		try {
			const response = await axios({
				method: "POST",
				url: "https://api.novaposhta.ua/v2.0/json/",
				data: payload,
			});
			commit("NOVA_POSHTA_SET_DEPARTMENS", response.data.data);
		} catch (e) {
			throw e;
		} finally {
			commit("NOVA_POSHTA_GET_DEPARTMENS_LOADING", false);
		}
	},
};

const mutations = {
	SAVE_SELECT_ADDRESS(state: State, data: UserAddress1C | null) {
		state.savedAddress = data;
	},
	SET_ADDRESS_LIST(state: State, data: Array<UserAddress1C>) {
		state.addresses = data;
	},
	SET_DOWNLOAD_PDF(state: State, data: DownloadPdfInfo) {
		state.productsPdf = data;
	},
	SET_REPEAT_PRODUCTS(state: State, data: RepeatProductData[]) {
		state.repeatProducts = data;
	},
	SET_ORDER(state: State, data: Order) {
		state.orderResult = data;
	},
	SET_ORDER_CHECK(state: State, data: CheckResult) {
		state.orderCheckResult = data;
	},
	SET_DELIVERY_METHODS(state: State, data: Array<DeliveryMethod>) {
		state.deliveryMethods = data;
	},
	SET_COURIER_PRODUCT(state: State, payload: Product) {
		state.courierProduct = payload;
	},
	SET_CERTIFICATE(state: State, data: Certificate) {
		state.certificate = data;
	},
	CLEAR_CERTIFICATE(state: State) {
		state.certificate = null;
	},
	GET_CERTIFICATE_LOADING(state: State, status: boolean) {
		state.certificateLoading = status;
	},
	SET_TRANSACTION(state: State, data: TransactionInfo) {
		state.transactionResult = data;
	},
	CHANGE_TRANSACTION_LOADING(state: State, status: boolean) {
		state.isTransactionLoading = status;
	},
	CHANGE_GIFT_LOADING(state: State, status: boolean) {
		state.isGiftLoading = status;
	},
	SET_GIFT_PRODUCT(state: State, product: Product) {
		state.giftProduct = product;
	},
	SET_INVOICE(state: State, data: Invoice) {
		state.invoiceResult = data;
	},
	CHANGE_INVOICE_LOADING(state: State, status: boolean) {
		state.isInvoiceLoading = status;
	},
	CHANGE_LOADING(state: State, status: boolean) {
		state.isLoading = status;
	},
	CHANGE_ADDRESS(state: State, data: any) {
		Object.assign(state.address, data);
	},
	CHANGE_ADDRESS_COURIER(state: State, data: AddressCourierData) {
		state.addressCourier = data;
	},
	NOVA_POSHTA_GET_DEPARTMENS_LOADING(state: State, status: boolean) {
		state.isDepartmentsLoading = status;
	},
	NOVA_POSHTA_GET_TOWNS_LOADING(state: State, status: boolean) {
		state.isTownsLoading = status;
	},
	CHANGE_DOWNLOAD_PDF_LOADING(state: State, status: boolean) {
		state.downloadPdfLoading = status;
	},
	NOVA_POSHTA_GET_ADDRESS_LOADING(state: State, status: boolean) {
		state.isAddressLoading = status;
	},
	NOVA_POSHTA_SET_TOWNS(state: State, data: Array<NovaPoshtaTown>) {
		state.towns = data;
	},
	NOVA_POSHTA_SET_DEPARTMENS(state: State, data: Array<NovaPoshtaDepartmen>) {
		state.departments = data;
	},
	SAVE_DELIVERY_ADDRESS(state: State, data: Delivery) {
		state.deliveryAddress = data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

import { mapActions, mapGetters, mapMutations } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  name: "form-application",
  data() {
    return {
      payload: {
        name: "",
        address: "",
        phone: "",
      },
      validationErrors: {},
    };
  },
  validations: {
    payload: {
      name: {
        required,
      },
      phone: {
        required,
        minLength: minLength(13),
      },
    },
  },
  computed: {
    ...mapGetters({
      requestLoading: "pages/requestLoading",
    }),
    nameErrors() {
      return this.computeErrors("payload", "name", {
        required: "validationRequired",
      });
    },
    phoneErrors() {
      return this.computeErrors("payload", "phone", {
        required: "validationRequired",
        minLength: { message: "validationMin", count: 13 },
      });
    },
  },
  created() {
    document.getElementById("html").classList.add("hide");
  },
  methods: {
    ...mapActions({
      sendForm: "pages/USER_REQUESTS",
    }),
    ...mapMutations({
      changeApplicationFormPopup: `popups/CHANGE_APPLICATION_FORM_POPUP`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.name_and_address = this.payload.address;
        obj.phone = this.payload.phone;
        this.sendForm(obj).then(() => {
          this.$toasted.success(this.$t("requestSuccessSend"));
          this.changeApplicationFormPopup(false);
        });
      }
    },
  },
};

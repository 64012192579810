import { mapActions, mapMutations } from "vuex";

export default {
  name: "activate-another-cosmetolog",
  data() {
    return {
      payload: {
        assignTo: "",
      },
    };
  },
  created() {
    document.getElementById("html").classList.add("hide");
  },
  methods: {
    ...mapActions({
      updateUserAssignCode: "profile/UPDATE_USER_DATA",
      fetchUser: "profile/FETCH_USER_DATA",
    }),
    ...mapMutations({
      changeAnotherCosmetologPopup: `popups/CHANGE_COSMETOLOG_POPUP`,
    }),
    async submit() {
      try {
        await this.updateUserAssignCode({ assign_to: this.payload.assignTo });
        if (this.isProfessional) {
          this.$toasted.success(this.$t("successAddDistributor"));
        } else {
          this.$toasted.success(this.$t("successAddCosmetolog"));
        }
        await this.fetchUser();
        this.changeAnotherCosmetologPopup(false);
      } catch (error) {
        const message = error.data?.message;
        if (message) {
          this.$toasted.error(message);
        } else {
          this.$toasted.error("Щось пішло не так, спробуйте пізніше");
        }
      }
    },
  },
};

import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { SearchResult } from "@/types/api";


interface State {
}

const state: State = {};

const getters = {};

const actions = {
	GET_SEARCH_LIST: async (state: ActionContext<State, RootState>, query: string | null) => {
		try {
			const response = await $http.get<{ data: SearchResult }>(`v1/search`, { params: { query } });

			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

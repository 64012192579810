import { mapActions, mapGetters, mapMutations } from "vuex";

import { maxLength, minLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
  name: "seminar-registration",
  data() {
    return {
      payload: {
        name: "",
        phone: "",
        comment: "",
        seminar_id: "",
        surname: "",
      },
      validationErrors: {},
      inputRefs: [],
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120),
      },
      phone: {
        required,
        minLength: minLength(17),
      },
      surname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120),
      },
    },
  },
  mounted() {
    this.payload.name = this.user.name;
    this.payload.phone = this.user.phone;
    this.payload.surname = this.user.surname;
  },
  computed: {
    ...mapGetters({
      currentSeminar: "seminars/currentSeminar",
      user: "profile/user",
    }),
    phoneErrors() {
      return this.computeErrors("payload", "phone", {
        required: "validationRequired",
        minLength: { message: "validationMin", count: 12 },
      });
    },
    nameErrors() {
      return this.computeErrors("payload", "name", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
      });
    },
    surnameErrors() {
      return this.computeErrors("payload", "surname", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
      });
    },
  },
  created() {
    document.getElementById("html").classList.add("hide");
  },
  methods: {
    ...mapMutations({
      changeSeminarRegistration: "seminars/SHOW_SEMINAR_POPUP",
      changeSeminarSuccess: "seminars/SHOW_SUCCESS_SEMINAR",
    }),
    ...mapActions({
      seminarRegistration: "seminars/SEMINAR_REGISTRATION",
    }),
    setItemRef(el) {
      if (el) {
        this.inputRefs.push(el);
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.payload.seminar_id = this.currentSeminar.id;
      this.seminarRegistration(this.payload).then(() => {
        document.getElementById("html").classList.remove("hide");

        this.changeSeminarRegistration(false);
        this.changeSeminarSuccess(true);

        this.$toasted.success(this.$t("Реєстрація успішна"));
      });
    },
  },
};

import { mapActions, mapGetters, mapMutations } from "vuex";

import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
  name: "notify-when-available",
  data() {
    return {
      payload: {
        name: "",
        phone: "",
        email: "",
        city: "",
      },
      validationErrors: {},
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(120),
        email,
      },
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(120),
      },
      phone: {
        required,
        minLength: minLength(13),
      },
      city: {
        required,
        maxLength: maxLength(120),
      },
    },
  },
  computed: {
    ...mapGetters({
      loadingSingIn: "auth/loadingSingIn",
      notifyData: "popups/shoeNotifyWhenAvailable",
      user: "profile/user",
      towns: "order/towns",
    }),
    nameErrors() {
      return this.computeErrors("payload", "name", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
        minLength: { message: "validationMin", count: 2 },
      });
    },
    phoneErrors() {
      return this.computeErrors("payload", "phone", {
        required: "validationRequired",
        minLength: { message: "validationMin", count: 13 },
      });
    },
    emailErrors() {
      return this.computeErrors("payload", "email", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
        email: "validationEmail",
      });
    },
    cityErrors() {
      return this.computeErrors("payload", "city", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
      });
    },
  },
  created() {
    document.getElementById("html").classList.add("hide");
    if (this.user) {
      this.payload = {
        name: `${this.user.name} ${this.user.secondName}`,
        phone: this.user.phone,
        email: this.user.email,
        city: this.user.city,
      };
    }
    this.onRequestTown(this.payload.city);
  },
  methods: {
    ...mapMutations({
      shoeNotifyWhenAvailable: "popups/SHOW_NOTIFY_WHEN_AVAILABLE",
      changeSeminarDetails: "popups/SHOW_SEMINAR_DETAILS_POPUP",
    }),
    ...mapActions({
      fetchData: "products/SET_PRODUCT_WHEN_AVAILABLE",
      fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
    }),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let obj = {
        name: this.payload.name,
        phone: this.payload.phone,
        email: this.payload.email,
        city: this.payload.city,
        ...this.notifyData.notifyPayload,
      };

      const showPopup = () => {
        this.shoeNotifyWhenAvailable({ status: false });
        this.changeSeminarDetails({
          status: true,
          data: {
            title: "",
            detail: "Дякуємо за звернення! Щойно продукт з'явиться в наявності, ми обов'язково Вам повідомимо",
          },
        });
      };

      this.fetchData(obj).then(showPopup).catch(showPopup);
    },
    onRequestTown(val) {
      const formData = JSON.stringify({
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: val,
        },
        apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
      });
      this.fetchTowns(formData).then(() => {
        const currentTown = this.towns.find(e => e.Description === this.payload.city);

        console.log(currentTown);

        if (currentTown) {
          this.payload.areaRef = currentTown.Area;
        } else {
          this.payload.areaRef = "";
        }
      });
    },
  },
};

import { $http } from "@/utils/https";
import type { InstagramPost } from "@/types/site";
import type { Product } from "@/types/shop";
import type { HeaderMessage } from "@/types/api";

interface State {}

const state: State = {};

const getters = {};

const actions = {
	GET_HEADER_MESSAGES: async () => {
		try {
			const response = await $http.get<{ data: Array<HeaderMessage> }>(`v1/header_messages`);
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	INSTAGRAM_POSTS: async () => {
		try {
			const response = await $http.get<{ data: Array<InstagramPost> }>(`v1/instagram_posts`);
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	GET_NEW_PRODUCTS: async () => {
		try {
			const response = await $http.get<{ data: Array<Product> }>(`v1/products/new`);
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	GET_BESTSELLER: async () => {
		try {
			const response = await $http.get<{ data: Array<Product> }>(`v1/products/bestseller`);
			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

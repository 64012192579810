import { VueSlideToggle } from "vue-slide-toggle";
import { isClickableInSidebarMenu, isProcessableItem, routeForMenuItem } from "@/helpers";

export default {
  name: "burger-nav",
  components: {
    VueSlideToggle,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
      openSubMenu: false,
    };
  },
  methods: {
    isClickableInSidebarMenu: isClickableInSidebarMenu,
    isProcessableItem: isProcessableItem,
    /**
     * @param {NavigationItem} navItem
     * @param {NavigationItem|null} parentNavItem
     * @return {Object}
     */
    routeForMenuItem(navItem, parentNavItem = null) {
      if (navItem.slug === "skinon-vs-acne") {
        return this.$localize({ name: "forum" });
      }
      if (navItem.slug === "promotions") {
        return this.$localize({ name: "promotions" });
      }
      return this.$localize(routeForMenuItem(navItem, parentNavItem));
    },
  },
};

import { mapGetters, mapMutations } from "vuex";
import login from "../popups/login/index.vue";
import registration from "../popups/registration/index.vue";
import reset from "../popups/reset/index.vue";
import restore from "../popups/restore/index.vue";
import addressesList from "../popups/addresses-list/index.vue";
import instagramPost from "../popups/instagram-post/index.vue";
import successRegister from "../popups/success-register/index.vue";
import anotherCosmetolog from "../popups/activate-another-cosmetolog/index.vue";
import fastBuy from "../popups/fast-buy/index.vue";
import defaultPrices from "../popups/default-prices/index.vue";
import formApplication from "../popups/form-application/index.vue";
import paymentInfo from "../popups/payment-info/index.vue";
import successRegisterAgent from "../popups/successRegisterAgent/index.vue";
import seminarRegistration from "@/components/popups/seminar-registration/index.vue";
import successRegisterSeminar from "@/components/popups/succes-seminar-register/index.vue";
import agentRegister from "@/components/popups/agent-register/index.vue";
import successOrder from "@/components/popups/success-order/index.vue";
import basket from "@/components/popups/basket/index.vue";
import seminarDetails from "@/components/popups/seminar-details/index.vue";
import seminarInfo from "@/components/popups/seminar-info/index.vue";
import notifyWhenAvailable from "@/components/popups/notify-when-available/index.vue";
import smsPopup from "@/components/popups/sms-popup/index.vue";
import distributorRegister from "@/components/popups/distributor-register/index.vue";
import remainings from "../popups/remainings/index.vue";
import metodistPopup from "../metodist-popup/index.vue";
import createManagerPopup from "../create-manager-popup/index.vue";
import loginManager from "../popups/login-manager/index.vue";
import videoModal from "../popups/video-modal/index.vue";
import seminarProfessionalOnlyPopup from "../popups/seminar-professional-only-popup/index.vue";
import reviewPopup from "../popups/review-popup/index.vue";

export default {
  name: "hidden",
  components: {
    login,
    registration,
    reset,
    restore,
    addressesList,
    instagramPost,
    successRegister,
    anotherCosmetolog,
    fastBuy,
    defaultPrices,
    formApplication,
    paymentInfo,
    successRegisterAgent,
    seminarRegistration,
    successRegisterSeminar,
    agentRegister,
    successOrder,
    basket,
    seminarDetails,
    notifyWhenAvailable,
    smsPopup,
    distributorRegister,
    remainings,
    seminarInfo,
    metodistPopup,
    createManagerPopup,
    loginManager,
    videoModal,
    seminarProfessionalOnlyPopup,
    reviewPopup,
  },
  computed: {
    ...mapGetters({
      loginPopup: "popups/loginPopup",
      loginManagerPopup: "popups/loginManagerPopup",
      registrationPopup: "popups/registrationPopup",
      resetPopup: "popups/resetPopup",
      restorePopup: "popups/restorePopup",
      addressesListPopup: "popups/addressesListPopup",
      instagramPopup: "popups/instagramPopup",
      successRegisterPopup: "popups/successRegisterPopup",
      anotherCosmetolog: "popups/anotherCosmetolog",
      fastBuyPopup: "popups/fastBuyPopup",
      showDefaultPrice: "popups/showDefaultPrice",
      formApplicationPopup: "popups/formApplicationPopup",
      paymentInfoPopup: "popups/paymentInfoPopup",
      isRegisterAgent: "popups/successRegisterAgent",
      showSeminarRegistration: "seminars/showSeminarPopup",
      isShowSeminarSuccess: "seminars/showSuccessPopup",
      agentRegister: "auth/agentRegister",
      isShowBasketPopup: "basket/isShowBasketPopup",
      isShowRemaingsPopup: "popups/showRemainingsPopup",
      showSuccessPaymentPopup: "popups/showSuccessPaymentPopup",
      showSeminarInfoPopup: "popups/showSeminarInfoPopup",
      seminarDetails: "popups/seminarDetails",
      shoeNotifyWhenAvailable: "popups/shoeNotifyWhenAvailable",
      showPhoneRegisterPopup: "auth/showPhoneRegisterPopup",
      isProfessional: "profile/isProfessional",
      distributorRegister: "auth/distributorRegister",
      metodistPopup: "metodist/popupOpen",
      showManagerPopup: "managers/showManagerPopup",
      seminarVideoModalStatus: "popups/seminarVideoModalStatus",
      seminarVideoModalUrl: "popups/seminarVideoModalUrl",
      showSeminarProfessionalOnlyPopup: "popups/showSeminarProfessionalOnlyPopup",
      showReviewModal: "popups/reviewModalStatus",
    }),
  },
  methods: {
    ...mapMutations({
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
      changeLoginManagerPopup: "popups/CHANGE_LOGIN_MANAGER_POPUP",
      changeRegistrationPopup: "popups/CHANGE_REGISTRATION_POPUP",
      changeResetPopup: "popups/CHANGE_RESET_POPUP",
      changeRestorePopup: "popups/CHANGE_RESTORE_POPUP",
      changeAddressesListPopup: "popups/CHANGE_ADDRESSES_LIST_POPUP",
      changeInstagramPostPopup: "popups/CHANGE_INSTAGRAM_POPUP",
      changeSuccessRegisterPopup: "popups/CHANGE_SUCCESS_REGISTER_POPUP",
      changeAnotherCosmetologPopup: `popups/CHANGE_COSMETOLOG_POPUP`,
      changeFastBuyPopup: `popups/CHANGE_FAST_BUY_POPUP`,
      changeDefaultPricesPopup: `popups/CHANGE_DEFAULT_PRICES`,
      changeApplicationFormPopup: `popups/CHANGE_APPLICATION_FORM_POPUP`,
      changePaymentInfoPopup: "popups/CHANGE_PAYMENT_INFO_POPUP",
      changeRegisterAgent: "popups/CHANGE_SHOW_SUCCESS_AGENT_REGISTER",
      showRemainingsPopup: "popups/CHANGE_REMAININGS_POPUP",
      changeSeminarRegistration: "seminars/SHOW_SEMINAR_POPUP",
      changeSeminarSuccess: "seminars/SHOW_SUCCESS_SEMINAR",
      changeRegisterAgentPopup: "auth/SHOW_AGENT_REGISTER_POPUP",
      changeRegisterDistributorPopup: "auth/SHOW_DISTRIBUTOR_REGISTER_POPUP",
      changeBasketPopup: "basket/SHOW_BASKET_POPUP",
      changeSuccessPayment: "popups/SHOW_SUCCESS_PAYMENT_POPUP",
      changeSeminarDetails: "popups/SHOW_SEMINAR_DETAILS_POPUP",
      changeSeminarInfo: "popups/SHOW_SEMINAR_INFO_POPUP",
      showNotifyPopup: "popups/SHOW_NOTIFY_WHEN_AVAILABLE",
      showSmsRegisterPopup: "auth/SHOW_PHONE_REGISTER_POPUP",
      closeMetodistPopup: "metodist/CLOSE_METODIST_POPUP",
      showManagerCreatePopup: "managers/SHOW_MANAGER_CREATE_POPUP",
      showSeminarVideoModal: "popups/SHOW_SEMINAR_VIDEO_MODAL",
      changeSeminarProfessionalOnlyPopup: "popups/SHOW_SEMINAR_PROFESSIONAL_ONLY_POPUP",
      changeReviewModal: "popups/CHANGE_REVIEW_MODAL",
    }),
  },
};

import userData from "./components/user-data/index.vue";
import userContact from "./components/user-contact/index.vue";
import userComment from "./components/user-comment/index.vue";
import { mapGetters, mapMutations } from "vuex";
import userChangePassword from "@/modules/profile/pages/user-info/components/user-change-password/index.vue";
import UserInfoHeader from "./components/user-info-header/index.vue";

export default {
  name: "user-info",
  components: {
    UserInfoHeader,
    userData,
    userContact,
    userComment,
    userChangePassword,
  },
  computed: {
    ...mapGetters({
      user: "profile/user",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
    }),
  },
  methods: {
    ...mapMutations({
      showNotifyPopup: "popups/SHOW_SEMINAR_DETAILS_POPUP",
    }),
    showProfiInfoPopup() {
      const getDetails = () => {
        if (this.isDistributor) {
          /*todo - ask for new text related to distributor */
          return {
            title: this.$t("infoAboutDistributorIdUsage"),
            detail:
              "Ви маєте унікальний ID, що дає можливість заробляти на рекомендаціях засобів у домашній догляд, тим самим не втрачати пацієнта та прибуток.<br/><br/>\n" +
              "\n" +
              "Використовуйте та поширюйте цей унікальний п’ятизначний код серед своїх пацієнтів!\n" +
              "При реєстрації на сайті, вашому пацієнту необхідно вказати ваш ID для того, щоб отримати знижку від вказаних цін на сайті для купівлі домашнього догляду.\n" +
              "Варто зазначити: ціни на сайті без реєстрації вказані з націнкою +70% до вартості косметолога.\n" +
              "Після реєстрації через ваш ID, пацієнту відображається вартість засобів з націнкою +50% до вартості спеціаліста. Тобто, клієнту набагато вигідніше придбати засоби саме після використання вашого ID.<br/><br/>\n" +
              "\n" +
              "\n" +
              "Всі оформлені (оплачені) замовлення ваших пацієнтів нараховують вам БОНУСИ.<br/><br/>\n" +
              "\n" +
              "Бонуси -  це різниця між ціною пацієнта та спеціаліста (50%); тобто це кошти, якими ви можете розраховуватись, оплачуючи свої особисті замовлення.\n" +
              "Накопичені бонуси відображаються на сайті та доступні на етапі оплати замовлення.<br/><br/>\n" +
              "\n" +
              "В особистому кабінеті, в розділі «Мої пацієнти з ID» ви можете відстежити перелік ваших клієнтів, що зареєструвались на сайті з вашим ID. Додатково ви маєте доступ до історії замовлень кожного вашого пацієнта.<br/><br/>\n" +
              "\n" +
              "Даний сайт дає вам фантастичні можливості, рекомендуйте засоби ТМ Renew  та ТМ Alphascience, щоб отримувати прибуток!\n",
          };
        }

        return {
          title: this.$t("infoAboutProfessionalIdUsage"),
          detail:
            "Ви маєте унікальний ID, що дає можливість заробляти на рекомендаціях засобів у домашній догляд, тим самим не втрачати пацієнта та прибуток.<br/><br/>\n" +
            "\n" +
            "Використовуйте та поширюйте цей унікальний п’ятизначний код серед своїх пацієнтів!\n" +
            "При реєстрації на сайті, вашому пацієнту необхідно вказати ваш ID для того, щоб отримати знижку від вказаних цін на сайті для купівлі домашнього догляду.\n" +
            "Варто зазначити: ціни на сайті без реєстрації вказані з націнкою +70% до вартості косметолога.\n" +
            "Після реєстрації через ваш ID, пацієнту відображається вартість засобів з націнкою +50% до вартості спеціаліста. Тобто, клієнту набагато вигідніше придбати засоби саме після використання вашого ID.<br/><br/>\n" +
            "\n" +
            "\n" +
            "Всі оформлені (оплачені) замовлення ваших пацієнтів нараховують вам БОНУСИ.<br/><br/>\n" +
            "\n" +
            "Бонуси -  це різниця між ціною пацієнта та спеціаліста (50%); тобто це кошти, якими ви можете розраховуватись, оплачуючи свої особисті замовлення.\n" +
            "Накопичені бонуси відображаються на сайті та доступні на етапі оплати замовлення.<br/><br/>\n" +
            "\n" +
            "В особистому кабінеті, в розділі «Мої пацієнти з ID» ви можете відстежити перелік ваших клієнтів, що зареєструвались на сайті з вашим ID. Додатково ви маєте доступ до історії замовлень кожного вашого пацієнта.<br/><br/>\n" +
            "\n" +
            "Даний сайт дає вам фантастичні можливості, рекомендуйте засоби ТМ Renew  та ТМ Alphascience, щоб отримувати прибуток!\n",
        };
      };

      this.showNotifyPopup({
        status: true,
        data: getDetails(),
      });
    },
    showDiscountInfoPopup() {
      this.showNotifyPopup({
        status: true,
        data: {
          title: "Система знижок %",
          detail:
            "Персональна ступенева знижка 5,10,12% - постійна система лояльності для партнерів SkinOn!<br/> <br/>\n" +
            "\n" +
            "Знижка надається на наступний місяць, в залежності від суми Ваших замовлень у поточному місяці.<br/>\n" +
            "•  від 12 000 грн. за місяць - 5%;<br/>\n" +
            "•  від 18 000 грн. за місяць - 10%;<br/>\n" +
            "•  від 25 000 грн. за місяць - 12%.<br/>\n",
        },
      });
    },
  },
};

import { mapActions, mapGetters } from "vuex";
import { compact } from "lodash";

export default {
  name: "clients-list",
  data() {
    return {
      clients: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "profile/user",
    }),
  },
  async created() {
    this.clients = (await this.fetchUserClients()).data;
  },
  methods: {
    compact,
    ...mapActions({
      fetchUserClients: "profile/GET_CLIENTS",
    }),
  },
};

import _ from "lodash";
import { VueSlideToggle } from "vue-slide-toggle";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { resolveProductImage, routeToProduct } from "@/helpers";

const DeliveryType = {
  PICKUP: "pickup",
  STORAGE: "storage",
  ADDRESS: "address",
  COURIER: "courier",
};
export default {
  name: "order-item",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    professional: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  components: {
    VueSlideToggle,
  },
  computed: {
    ...mapGetters({
      repeatProducts: "order/repeatProducts",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      user: "profile/user",
    }),
  },
  created() {
    if (this.$route.query.order) {
      if (this.order.id == this.$route.query.order) {
        this.open = true;
        this.$nextTick(() => {
          let scrollOptions = {
            behavior: "smooth",
            block: "center",
            inline: "center",
          };

          this.$el.scrollIntoView(scrollOptions);
        });
      }
    }
  },
  methods: {
    resolveProductImage,
    routeToProduct,
    ...mapActions({
      repeatOrder: `order/REPEAT_ORDER`,
    }),
    ...mapMutations({
      resetBasket: `basket/RESET_BASKET`,
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
    }),
    /**
     * @param {DeliveryMethod} delivery
     * @return {string|null}
     */
    getTitleForDeliveryInList(delivery) {
      if (!delivery) {
        return null;
      }

      if (delivery.type === DeliveryType.PICKUP) return this.$t("free");
      if (delivery.type === DeliveryType.STORAGE) return this.$t("deliveries.accordingToTheCarrierTariffs");
      if (delivery.type === DeliveryType.ADDRESS) return this.$t("deliveries.accordingToTheCarrierTariffs");
      if (delivery.type === DeliveryType.COURIER) return "100" + this.$t("grn");

      if (delivery.price <= 0) return "";

      return delivery.price + " " + this.$t("grn");
    },
    copyToClipboard(value) {
      const textarea = document.createElement("textarea");
      textarea.value = value;

      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 99999);

      try {
        document.execCommand("copy");
        console.log("Copied to clipboard: ", value);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
      this.$toasted.success("Номер TTH успішно скопійовано");
      document.body.removeChild(textarea);
    },
    async repeat() {
      await this.repeatOrder(this.order.id);

      await this.resetBasket();

      _.each(this.repeatProducts, repeatProduct => {
        this.addToBasket({
          select_count: repeatProduct.selectCount,
          product_price: repeatProduct.productPrice,
          product: repeatProduct.product,
        });
      });

      this.$router.push({ name: "buy-flow-basket" }).catch(() => {});
    },
  },
};

<template>
  <div class="ingredient">
    <div class="ingredient-wrap">
      <div class="ingredient-image">
        <img :src="info.file" alt="" />
        <div class="ingredient-image__text">
          <div class="ingredient-image__title">{{ info.title }}</div>
          <div class="ingredient-image__description" v-html="info.shortDescription"></div>
        </div>
        <div class="ingredient-overlay"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ingredient",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.ingredient {
  width: 100%;
  height: 100%;

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: ease 0.3s;
    z-index: 2;

    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &-wrap {
    width: 100%;
    height: 100%;
  }

  &-image {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__text {
      z-index: 3;
      position: absolute;
      bottom: 56px;
      left: 56px;
      right: 10px;
    }

    &__title {
      font-weight: normal;
      font-size: 40px;
      line-height: 40px;
      color: #ffffff;
      margin-bottom: 12px;
    }

    &__description {
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1021px) {
  .ingredient-image__text {
    left: 10px;
  }
  .ingredient-image__title {
    font-size: 26px;
  }
  .ingredient-image__description {
    font-size: 14px;
  }
}
</style>

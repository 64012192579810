import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { IProductWhenAvailablePayload } from "@/store/modules/types/products";
import type { FilterItem, Product } from "@/types/shop";
import type { PaginatorData } from "@/types/api";
import type { User } from "@/types/user";

interface State {
	showNotifyLoader: boolean;
}

const state: State = {
	showNotifyLoader: false,
};

const getters = {
	showNotifyLoader: (state: State) => state.showNotifyLoader,
};

export type ICatalogFilter = {
	catalog_type?: string;
	sort_filter_type?: string;
	brands?: string;
	series?: string;
	categories?: string;
}
type PaginatorInfo = { page: number, perPage: number }

const actions = {
	SHOW_POPUP_PRODUCT_WHEN_AVAILABLE: async (context: ActionContext<State, RootState>, payload: IProductWhenAvailablePayload) => {
		const currentUser: User | null = context.rootGetters["profile/user"];
		if (!currentUser || !currentUser.city) {
			context.commit("popups/SHOW_NOTIFY_WHEN_AVAILABLE", { status: true, notifyPayload: payload }, { root: true });
			return;
		}

		const showThanksPopup = () => {
			context.commit("popups/SHOW_SEMINAR_DETAILS_POPUP", {
				status: true,
				data: {
					title: "",
					detail: "Дякуємо за звернення! Щойно продукт з'явиться в наявності, ми обов'язково Вам повідомимо",
				},
			}, { root: true });
		};

		try {
			await context.dispatch("SET_PRODUCT_WHEN_AVAILABLE", payload);
			showThanksPopup();
		} catch (e) {
			showThanksPopup();
		}
	},
	SET_PRODUCT_WHEN_AVAILABLE: async ({ commit }: ActionContext<State, RootState>, payload: IProductWhenAvailablePayload) => {
		commit("SHOW_NOTIFY_LOADER", true);
		try {
			await $http.post<{ message: string }>("v1/products/notify-when-available", payload);
		} catch (e) {
			throw e;
		} finally {
			commit("SHOW_NOTIFY_LOADER", false);
		}
	},
	GET_PRODUCTS: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
		try {
			const response = await $http.get<{ data: Array<Product> }>(`v1/products`, {
				params: payload,
			});

			return response.data;
		} catch (e) {
			throw e;
		}
	},
	GET_CATALOG_FILTERS: async ({ commit }: ActionContext<State, RootState>) => {
		try {
			const response = await $http.get<{ data: Array<FilterItem> }>(`v1/catalog/filters`);

			return response.data.data;
		} catch (e) {
			throw e;
		}
	},
	GET_PRODUCTS_FOR_CATALOG: async ({ commit }: ActionContext<State, RootState>, catalogPayload: (PaginatorInfo & ICatalogFilter)) => {
		try {
			type BrandSeries = {
				id: number;
				name: string;
				title: string;
			}
			type Group = {
				brandSeries: BrandSeries;
				products: Array<Product>;
			};

			type CatalogResponse = Array<Product> | Array<Group>;

			const response = await $http.get<PaginatorData<CatalogResponse>>(`v1/catalog/products`, {
				params: catalogPayload,
			});

			return response.data;
		} catch (e) {
			throw e;
		}
	},
};

const mutations = {
	SHOW_NOTIFY_LOADER(state: State, status: boolean) {
		state.showNotifyLoader = status;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

import { mapActions } from "vuex";

export default {
  name: "seminars",
  data() {
    return {
      coursesIsLoading: false,
      courseThemeList: [],
      courseThemes: [],
      coursesLimit: 4,
    };
  },
  async created() {
    this.coursesIsLoading = true;

    this.courseThemeList = await this.getCourseThemes();

    if (this.courseThemeList.length > this.coursesLimit) {
      this.courseThemeList.forEach((course, idx) => {
        if (idx <= this.coursesLimit - 1) {
          this.courseThemes.push(course);
        }
      });
    } else {
      this.courseThemes = this.courseThemeList;
    }

    this.coursesIsLoading = false;
  },
  methods: {
    ...mapActions({
      getCourseThemes: "courses/GET_COURSE_THEMES",
    }),
    getMoreCourses() {
      this.coursesLimit += 4;
      this.courseThemeList.forEach((course, idx) => {
        if (this.courseThemes[idx]?.id !== course.id && idx <= this.coursesLimit - 1) {
          this.courseThemes.push(this.courseThemeList[idx]);
        }
      });
    },
  },
};

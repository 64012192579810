export default {
  props: {
    outline: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Submit",
    },
    progress: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "submit",
    },
    color: {
      type: String,
      default: "#4F4F4F",
    },
  },
  methods: {
    changeEvent() {
      this.$emit("change-event");
    },
  },
};

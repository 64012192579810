import { mapGetters, mapMutations } from "vuex";

export default {
  name: "remainings",
  methods: {
    ...mapMutations({
      showRemainingsPopup: "popups/CHANGE_REMAININGS_POPUP",
    }),
    navigateTo(params) {
      this.showRemainingsPopup(false);
      this.$router.push({ name: params }).catch(() => {});
      try {
        this.$router.push({ name: params }).catch(() => {});
      } catch (e) {
        console.log(e);
      }
    },
    getProducts() {
      console.log(this.orderCheckResult);
    },
  },
  computed: {
    ...mapGetters({
      orderCheckResult: "order/orderCheckResult",
    }),
  },
};

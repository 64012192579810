import { mapActions, mapGetters } from "vuex";
import { routeToProduct } from "@/helpers";
import { changeSlide } from "vue-slick-carousel/src/innerSliderUtils";

export default {
  name: "storage-invoice",
  data() {
    return {
      brandSelected: [],
      seriesSelected: [],
      countChanged: [],
      calculated: false,
      productCount: "",
      comment: "",
      sendFormProgress: false,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    counterType: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isManager: "profile/isManager",
    }),
    productClass() {
      if (this.counterType && !this.calculated) {
        return "productClassCounter";
      }
      if (this.counterType && this.calculated) {
        return "productClassCalculated";
      }
    },
    isOpenBrand() {
      return brandId => {
        let idx = this.brandSelected.findIndex(item => item.brand.id === brandId);
        return idx >= 0;
      };
    },
    isOpenSeries() {
      return seriesId => {
        let idx = this.seriesSelected.findIndex(item => item.brandSeries.id === seriesId);
        return idx >= 0;
      };
    },
  },
  methods: {
    changeSlide,
    routeToProduct,
    ...mapActions({
      submitData: "remainings/SEND_REMAININGS",
    }),
    openBrand(brand, event) {
      let idx = this.brandSelected.findIndex(item => item.brand.id === brand.brand.id);
      if (idx < 0) {
        this.brandSelected.push(brand);
      } else {
        this.brandSelected.splice(idx, 1);
      }
    },
    openSeries(series, event) {
      let idx = this.seriesSelected.findIndex(item => item.brandSeries.id === series.brandSeries.id);
      if (idx < 0) {
        this.seriesSelected.push(series);
      } else {
        this.seriesSelected.splice(idx, 1);
      }
    },
    calculateSales() {
      this.calculated = true;
      console.log("seriesSelected", JSON.parse(JSON.stringify(this.seriesSelected)));
      console.log("brandSelected", JSON.parse(JSON.stringify(this.brandSelected)));
      console.log("countChanged", JSON.parse(JSON.stringify(this.countChanged)));
      this.$emit("calculate");
    },

    getItemCount(id) {
      const item = this.countChanged.find(item => item.product_price_id === id);
      return item ? item.count : 0;
    },

    handleCountChange(event, id, quantity, price, product) {
      let inputCount = parseInt(event.target.value);
      if (isNaN(inputCount)) inputCount = 0;
      if (inputCount > quantity) inputCount = quantity;
      if (this.countChanged.find(item => item.product_price_id === id)) {
        const item = this.countChanged.find(item => item.product_price_id === id);
        if (inputCount > quantity) {
          item.count = quantity;
          return;
        }
        if (inputCount <= 0) {
          item.count = 0;
          return;
        }
        item.count = inputCount;
      } else {
        this.countChanged.push({
          product_price_id: id,
          count: inputCount,
          quantity: quantity,
          price: price,
          product: product,
        });
      }

      this.$forceUpdate();
      console.log(JSON.parse(JSON.stringify(this.countChanged)));
      this.$emit("countChange", this.countChanged);
    },

    incrementCount(id, quantity, price, product) {
      if (this.countChanged.find(item => item.product_price_id === id)) {
        const item = this.countChanged.find(item => item.product_price_id === id);
        if (item.count >= quantity) return;
        item.count += 1;
      } else {
        this.countChanged.push({
          product_price_id: id,
          count: 1,
          quantity: quantity,
          price: price,
          product: product,
        });
      }
      console.log(JSON.parse(JSON.stringify(this.countChanged)));
      this.$emit("countChange", this.countChanged);
    },

    decrementCount(id) {
      const item = this.countChanged.find(item => item.product_price_id === id);
      if (item && item.count > 0) {
        item.count -= 1;
      }

      if (item.count === 0) {
        this.removeItem(id);
      }
      this.$emit("countChange", this.countChanged);
    },

    removeItem(id) {
      const index = this.countChanged.findIndex(item => item.product_price_id === id);
      if (index !== -1) {
        this.countChanged.splice(index, 1);
      }
    },

    getTotalChangedSum() {
      return this.countChanged.reduce((sum, item) => sum + item.price * item.count, 0);
    },

    async sendForm() {
      try {
        this.sendFormProgress = true;
        const response = await this.submitData({ comment: this.comment, products: this.countChanged });
        this.sendFormProgress = false;
        if (response.status === 200) {
          this.$toasted.success(response.data.message);
        }
      } catch (e) {
        throw e;
      }
    },
  },
};

import questionCard from "../../components/question-card/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
  name: "questions",
  data() {
    return {
      payload: {
        message: "",
      },
      validationErrors: {},
      showForm: false,
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      message: {
        required,
      },
    },
  },
  components: {
    questionCard,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      product: "card/product",
    }),
    messageErrors() {
      return this.computeErrors("payload", "message", {
        required: "validationRequired",
      });
    },
  },
  methods: {
    ...mapActions({
      sendQuestion: "card/SEND_QUESTION",
    }),
    ...mapMutations({
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
    }),
    submit() {
      if (this.isAuthenticated) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let obj = {};
          obj.product_id = this.product.id;
          obj.user_question = this.payload.message;
          this.sendQuestion(obj).then(() => {
            this.payload.message = "";
            this.$v.$reset();
            this.showForm = false;
            this.$toasted.success(this.$t("successQuestionSend"));
          });
        }
      } else {
        this.changeLoginPopup(true);
      }
    },
  },
};

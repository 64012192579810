interface State {
	fixBody: any;
	showCatalogMenu: any;
	globalLoader: any;
	showFilter: any;
	openMenu: any;
	openSecondMenu: any;
	openFirstMenu: any;
	breadcrumbsList: any;
	propsSecondMenu: any;
	popup: any;
	popupTextShow: any;
}

const state: State = {
	globalLoader: false,
	fixBody: false,
	openMenu: true,
	openSecondMenu: false,
	openFirstMenu: false,
	breadcrumbsList: null,
	propsSecondMenu: null,
	showFilter: true,
	showCatalogMenu: false,
	popup: {
		title: "",
		text: "",
	},
	popupTextShow: false,
};

const getters = {
	globalLoader: (state: State) => state.globalLoader,
	popup: (state: State) => state.popup,
};

const mutations = {
	SHOW_GLOBAL_LOADER(state: State, status: any) {
		state.globalLoader = status;
	},
	FIX_BODY(state: State, param: any) {
		const body = document.querySelector("body");
		if (!body) return;
		body.classList[param ? "add" : "remove"]("fix");
		state.fixBody = param;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};

import { mapGetters } from "vuex";

export default {
  name: "success-register",
  computed: {
    ...mapGetters({
      currentSeminar: "seminars/currentSeminar",
    }),
  },
  created() {
    document.getElementById("html").classList.add("hide");
  },
};
